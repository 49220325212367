import {
  Tooltip,
  Typography,
  TextField,
  Grid,
  Box,
  Input as MUInput,
} from '@material-ui/core'
import { useLocation } from '@reach/router'
import Auth from '@aws-amplify/auth'
import classnames from 'classnames'
import { TFunction } from 'i18next'
import { produce } from 'immer'
import React, { useEffect, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import {
  accountSettingValidation,
  checkIfSubmittable,
  getErrorMsg,
  passwordValidation,
  paymentValidation,
} from '../../../utils/validation'
import { useAppState } from '../../appState'
// import editIcon from '../../assets/images/edit.svg'
// import paypalIcon from '../../assets/images/paypal-light.svg'
// import venmoIcon from '../../assets/images/venmo_logo.svg'
import uploadIcon from '../../assets/images/upload.svg'
import birdFace from '../../assets/images/Bird-face.png'
import axios from '../../axios'
import Button from '../../components/button'
import { styles } from '../../components/dashboard/styles'
// import Input from '../../components/input'
// import SocialMediaInput from '../../components/socialMediaInput'
import IOSSwitch from './../../components/IOSSwitch'
import { ENDPOINTS, regExps, COLORS } from './../../constants'
import CHRUploadAvatar from './../AmplifyUpload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
// import PaymentRadioInput from '../paymentRadioInput'
import MaskedInput from 'react-text-mask'
import Popup from '../popups/index'

function TextMask(props: any) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      style={{
        paddingTop: 17,
        paddingBottom: 17,
        paddingLeft: 20,
        marginTop: 20,
        marginBottom: 20,
      }}
    />
  )
}

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

interface SettingsTabProps {
  t: TFunction
  updateName: Function
  updateUsername: Function
}

const SettingsTab = ({
  t,
  updateName,
  updateUsername,
  ...rest
}: SettingsTabProps) => {
  const [appState, dispatch] = useAppState()
  const { hash } = useLocation()
  const paypalSettingsRef = useRef(null)
  const classes = styles()
  const { socialUrls } = rest

  const [passwordShown, setPasswordShown] = useState(false)
  const [password2Shown, setPassword2Shown] = useState(false)
  const [password3Shown, setPassword3Shown] = useState(false)
  const [accountProfileFields, setAccountProfileFields] = useState({
    email: '',
    userName: '',
    fullName: '',
    imageKey: '',
    error: null,
  })

  const [payment, setPayment] = useState({
    isEditVisible: true,
    isPaymentDisabled: true,
    paypalEmail: '',
    venmoAccount: '',
    defaultPayment: 'PAYPAL',
    emailErrorMsg: '',
    venmoErrorMsg: '',
    defaultPaymentErrorMsg: false,
    isLoading: false,
  })

  const parseSocialsInit = (socialsJson: any) => {
    const socialUrlsConverted = socialsJson ? JSON.parse(socialsJson) : {}
    return {
      instagramUrl: socialUrlsConverted?.instagramUrl || '',
      instagramInactive: socialUrlsConverted?.instagramInactive,
      facebookUrl: socialUrlsConverted?.facebookUrl || '',
      facebookInactive: socialUrlsConverted?.facebookInactive,
      twitterUrl: socialUrlsConverted?.twitterUrl || '',
      twitterInactive: socialUrlsConverted?.twitterInactive,
      pinterestUrl: socialUrlsConverted?.pinterestUrl || '',
      pinterestInactive: socialUrlsConverted?.pinterestInactive,
      youTubeUrl: socialUrlsConverted?.youTubeUrl || '',
      youTubeInactive: socialUrlsConverted?.youTubeInactive,
    }
  }

  const [errors, setErrors] = useState({
    instagram: '',
    facebook: '',
    twitter: '',
    pinterest: '',
    youTube: '',
  })

  const [socials, setSocials] = useState(parseSocialsInit(socialUrls))

  const [socialErrorMsg, setSocialErrorMsg] = useState('')
  const [isSocialLoading, setIsSocialLoading] = useState(false)

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown)
  }

  const togglePassword2Visiblity = () => {
    setPassword2Shown(!password2Shown)
  }

  const togglePassword3Visiblity = () => {
    setPassword3Shown(!password3Shown)
  }

  useEffect(() => {
    if (hash === '#paypalSettings') paypalSettingsRef.current.scrollIntoView()
  })

  useEffect(() => {
    try {
      ;(async () => {
        const {
          data: { data },
        } = await axios.get(ENDPOINTS.user.replace(':id', appState.userId))
        setAccountProfileFields({
          email: data.email,
          userName: data.username,
          fullName: data.name,
          imageKey: data.image,
        })
        setSocials(parseSocialsInit(data.socialUrls))
        if (!data.username) {
          setUserNameErrorMsg('userNameRequired')
        }
        setIsNewsletterChecked(data.newsletterSubscription)
        if (!!data.paypalAccount || !!data.venmoAccount) {
          setPayment({
            ...payment,
            paypalEmail: data.paypalAccount || '',
            venmoAccount: data.venmoAccount || '',
            defaultPayment: data.defaultPayment || 'PAYPAL',
            isPaymentDisabled: true,
            isEditVisible: true,
          })
        } else {
          setPayment({
            ...payment,
            isPaymentDisabled: false,
            isEditVisible: false,
          })
        }
      })()
    } catch (error) {
      setAccountProfileFields({ error: true })
    }
  }, [])

  const [isNewsletterChecked, setIsNewsletterChecked] = useState(true)
  const [isChanging, setIsChanging] = useState(false)
  const [fullNameErrorMsg, setFullNameErrorMsg] = useState('')
  const [userNameErrorMsg, setUserNameErrorMsg] = useState('')
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('')
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('')
  const [passwordMatchingError, setPasswordMatchingError] = useState('')
  const [incorrectPassword, setIncorrectPassword] = useState('')
  const [passwordChanged, setPasswordChanged] = useState('')
  const [nameChanged, setNameChanged] = useState('')
  const [isDetailsSaved, setIsDetailsSaved] = useState(false)

  const [changePasswordFields, setChangePasswordFields] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  })

  const handleNewslettersSwitch = () => {
    setIsNewsletterChecked(!isNewsletterChecked)
  }

  async function handleSaveDetailsChangesClick() {
    const result = await checkAndSavePasswordChanges()

    if (!(result instanceof Error)) {
      await saveAccountProfile()
    }
  }

  async function handlePaypalSave() {
    setPayment({
      ...payment,
      isLoading: true,
    })

    if (payment.paypalEmail || payment.venmoAccount) {
      try {
        const isFormValid = await paymentValidation({
          email: payment.paypalEmail,
          venmoAccount: payment.venmoAccount,
        })
        if (isFormValid) {
          const isValidDefaultOption =
            (payment.paypalEmail && payment.defaultPayment === 'PAYPAL') ||
            (payment.venmoAccount && payment.defaultPayment === 'VENMO')
          if (isValidDefaultOption) {
            const payoutData = {
              paypalAccount: payment.paypalEmail,
              // venmoAccount: payment.venmoAccount,
              // defaultPayment: payment.defaultPayment,
            }

            await axios.patch(
              ENDPOINTS.user.replace(':id', appState.userId),
              payoutData
            )

            setPayment({
              ...payment,
              isLoading: false,
              isEditVisible: true,
              isPaymentDisabled: true,
            })
          } else {
            setPayment({
              ...payment,
              isLoading: false,
              defaultPaymentErrorMsg:
                'default payment method must be valid option',
            })
          }
        }
      } catch (error) {
        if (
          error?.response?.data?.message ===
          '"paypalAccount" must be a valid email'
        ) {
          setPayment({ ...payment, isLoading: false })
          setPayment({ ...payment, emailErrorMsg: 'invalidEmail' })
        } else {
          setPayment({
            ...payment,
            emailErrorMsg: getErrorMsg(error, 'email'),
            venmoErrorMsg: getErrorMsg(error, 'venmoAccount'),
          })
        }
      }
    } else {
      setPayment({
        ...payment,
        defaultPaymentErrorMsg: 'please enter an payment method',
      })
    }
  }

  const handleOnEdit = () => {
    setPayment({
      ...payment,
      isEditVisible: false,
      isPaymentDisabled: false,
    })
  }

  const handleDefaultPayment = (e: any) => {
    const value = e.currentTarget.value
    setPayment({
      ...payment,
      defaultPaymentErrorMsg: false,
      defaultPayment: value,
    })
  }

  const handleChangePasswordField = (property: string, value) => {
    const newState = produce(changePasswordFields, draftedState => {
      draftedState[property] = value
    })

    setChangePasswordFields(newState)
  }

  const handleChangeAccountProfileField = (property: string, value) => {
    const newState = produce(accountProfileFields, draftedState => {
      draftedState[property] = value
    })

    setAccountProfileFields(newState)
  }

  async function saveAccountProfile() {
    try {
      if (accountProfileFields.userName === null) {
        accountProfileFields.userName = ''
      }

      await accountSettingValidation({
        email: accountProfileFields.email,
        userName: accountProfileFields.userName,
        fullName: accountProfileFields.fullName,
      })

      const accountProfileData = {
        name: accountProfileFields.fullName,
        username: accountProfileFields.userName,
        newsletterSubscription: isNewsletterChecked,
      }

      setIsChanging(true)

      await axios.patch(
        ENDPOINTS.user.replace(':id', appState.userId),
        accountProfileData
      )

      setIsChanging(false)
      setIsDetailsSaved(true)
      await new Promise(done => setTimeout(() => done(), 1500))
      setIsDetailsSaved(false)
      setNameChanged('nameChanged')
      updateName(accountProfileFields.fullName)
      updateUsername(accountProfileFields.userName)

      const userProfile = appState.userProfile
      userProfile.userName = accountProfileFields.userName
      userProfile.fullName = accountProfileFields.fullName

      dispatch({ type: 'UPDATE_USER_DATA', userProfile })
    } catch (error) {
      if (error?.response?.data?.message == 'username is already exists') {
        setUserNameErrorMsg('uniqueUsername')
        setNameChanged('')
        setIsChanging(false)
      } else {
        setFullNameErrorMsg(getErrorMsg(error, 'fullName'))
        setUserNameErrorMsg(getErrorMsg(error, 'userName'))
        setNameChanged('')
      }
    }
  }

  const clearValidationMessages = () => {
    setPasswordChanged('')
    setPasswordErrorMsg('')
    setConfirmPasswordErrorMsg('')
    setPasswordMatchingError('')
    setIncorrectPassword('')
  }

  async function checkAndSavePasswordChanges() {
    const { oldPassword, password, confirmPassword } = changePasswordFields

    if (!oldPassword && !password && !confirmPassword) {
      return setIncorrectPassword('')
    }

    if (!oldPassword && (password || confirmPassword)) {
      setIncorrectPassword('passwordIncorrect')
      return new Error('')
    }

    try {
      await passwordValidation({
        password,
        confirmPassword,
      })
    } catch (error) {
      setPasswordErrorMsg(getErrorMsg(error, 'password'))
      setConfirmPasswordErrorMsg(getErrorMsg(error, 'confirmPassword'))
      return new Error('')
    }

    if (password !== confirmPassword) {
      setPasswordMatchingError('passwordsNotMatched')
      return new Error('')
    }

    setPasswordMatchingError('')

    try {
      await savePasswordChanges()
    } catch (err) {
      return new Error('')
    }
  }

  async function savePasswordChanges() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(
        currentUser,
        changePasswordFields.oldPassword,
        changePasswordFields.password
      )
      setPasswordChanged('passwordChanged')
      setChangePasswordFields({
        oldPassword: '',
        password: '',
        confirmPassword: '',
      })
      setIncorrectPassword('')
    } catch (error) {
      setPasswordChanged('')
      setIncorrectPassword('passwordIncorrect')
      throw error
    }
  }

  const handleChangeSocial = async () => {
    setIsSocialLoading(true)
    try {
      let hasError = false
      if (socials.instagramUrl === '') {
        setErrors({ ...errors, instagram: t('dashboard.msgInstagram') })
        setSocials({ ...socials, instagramInactive: true })
        setIsSocialLoading(false)
      }
      if (
        socials.instagramUrl !== '' &&
        !regExps.instagramLink.test(socials.instagramUrl)
      ) {
        setErrors({ ...errors, instagram: t('dashboard.msgInstagramFormat') })
        hasError = true
        setIsSocialLoading(false)
      }
      if (!hasError && socials.instagramUrl !== '') {
        setSocials({ ...socials, instagramInactive: false })
      }
      if (!hasError) {
        const socialData = {
          socialUrls: JSON.stringify(socials),
        }

        const { data } = await axios.patch(
          ENDPOINTS.user.replace(':id', appState.userId),
          socialData
        )
        setIsSocialLoading(false)
      }
      await new Promise(() =>
        setTimeout(() => {
          setErrors({ ...errors, instagram: '' })
        }, 3000)
      )
    } catch (error) {
      if (error?.response?.data?.message) {
        setIsSocialLoading(false)
        setSocialErrorMsg(error?.response?.data?.message)
      } else {
        setIsSocialLoading(false)
      }
    }
  }

  const handleChangeSocialStatus = async (socialUrls: any) => {
    try {
      const socialData = {
        socialUrls: JSON.stringify(socialUrls),
        username: accountProfileFields.userName,
      }

      const { data } = await axios.patch(
        ENDPOINTS.user.replace(':id', appState.userId),
        socialData
      )

      setIsSocialLoading(false)
    } catch (error) {
      if (error?.response?.data?.message) {
        setIsSocialLoading(false)
        setSocialErrorMsg(error?.response?.data?.message)
      } else {
        setIsSocialLoading(false)
      }
    }
  }

  const handleConntected = async (data: any, type: string) => {
    let hasError = false
    let finalData = data
    switch (type) {
      case 'instagram': {
        setErrors({ ...errors, instagram: '' })
        if (data.instagramUrl === '') {
          setErrors({ ...errors, instagram: t('dashboard.msgInstagram') })
          finalData = {
            ...finalData,
            instagramInactive: true,
          }
        }
        if (
          data.instagramUrl !== '' &&
          !regExps.instagramLink.test(data.instagramUrl)
        ) {
          setErrors({ ...errors, instagram: t('dashboard.msgInstagramFormat') })
          hasError = true
        }
        break
      }
      default: {
        break
      }
    }
    if (!hasError) {
      setSocials(finalData)
      handleChangeSocialStatus(finalData)
    }
    await new Promise(() =>
      setTimeout(() => {
        setErrors({ ...errors, instagram: '' })
      }, 3000)
    )
  }

  const subtitleClasses = classnames({
    [classes.titlesSpacing]: true,
    [classes.subtitles]: true,
  })

  const usernameOnChange = event => {
    handleChangeAccountProfileField('userName', event.target.value)
    setUserNameErrorMsg('')
    setFullNameErrorMsg('')
    setNameChanged('')
  }

  const usernameOnKeyPress = event => {
    !isChanging && checkIfSubmittable(event, handleSaveDetailsChangesClick)
  }

  const showEnterUsernamePopup =
    appState.username === null && window.location.hash === '#settings'

  return (
    <div className={classes.settingsWrapper}>
      <Typography
        variant="subtitle1"
        component="p"
        className={classes.errorMsg}
      >
        {accountProfileFields.error && t('messages.somethingWentWrong')}
      </Typography>
      <p className={classes.subtitles}>{t('dashboard.yourDetails')}</p>
      <section className={classes.form}>
        <section className={classes.personalInfoForm}>
          <label
            className={classes.avatarCircle}
            htmlFor="upload-dashboard-avatar"
          >
            <CHRUploadAvatar
              inputId="upload-image-settings-tab"
              circleInputId="upload-dashboard-avatar"
            />
          </label>
          {appState.userProfile?.imageError && (
            <Typography
              variant="subtitle1"
              component="p"
              className={`${classes.errorMsg} ${classes.uploadError}`}
            >
              {t('messages.uploadFailed')}
            </Typography>
          )}
          <label
            htmlFor="upload-image-settings-tab"
            className={classes.lightButtonLabel}
          >
            <span className={classes.lightButton}>
              <img
                src={uploadIcon}
                alt="upload-icon"
                className={classes.uploadIcon}
              />{' '}
              <span className={classes.replaceImageText}>
                {t('dashboard.replaceImage')}
              </span>
            </span>
          </label>
        </section>
        <div className={classes.inputWrapper}>
          <p className={classes.errorMsg}>
            {fullNameErrorMsg && t(`dashboard.${fullNameErrorMsg}`)}
          </p>
          <TextField
            fullWidth
            label={t('shared.fullName')}
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldColor,
              classes: {
                input: classes.textFieldInput,
              },
            }}
            InputLabelProps={{
              classes: {
                formControl: classes.textFieldLabelFormControl,
                shrink: classes.textFieldLabelShrink,
                root: classes.textFieldLabelRoot,
              },
            }}
            variant="filled"
            value={accountProfileFields.fullName}
            className={classes.inputSpacing}
            onChange={event => {
              handleChangeAccountProfileField('fullName', event.target.value)
              setFullNameErrorMsg('')
              setUserNameErrorMsg('')
              setNameChanged('')
            }}
            onKeyPress={event =>
              !isChanging &&
              checkIfSubmittable(event, handleSaveDetailsChangesClick)
            }
          />
        </div>
        <div className={classes.inputWrapper}>
          <p className={classes.errorMsg}>
            {userNameErrorMsg && t(`dashboard.${userNameErrorMsg}`)}
          </p>
          {showEnterUsernamePopup ? (
            <UsernamePopup
              alt={t('popUps.imageAlts.birdFace')}
              classes={classes}
              open={showEnterUsernamePopup}
            >
              <UsernameTextField
                label={t('shared.username')}
                classes={classes}
                style={{
                  outline: userNameErrorMsg
                    ? `1px solid ${COLORS.dangerRed}`
                    : `none`,
                }}
                value={accountProfileFields.userName}
                onChange={usernameOnChange}
                onKeyPress={usernameOnKeyPress}
              />
            </UsernamePopup>
          ) : (
            <UsernameTextField
              label={t('shared.username')}
              classes={classes}
              style={{
                outline: userNameErrorMsg
                  ? `1px solid ${COLORS.dangerRed}`
                  : `none`,
              }}
              value={accountProfileFields.userName}
              onChange={usernameOnChange}
              onKeyPress={usernameOnKeyPress}
            />
          )}
        </div>
        <div className={classes.inputWrapper}>
          <TextField
            fullWidth
            label={t('shared.emailAddress')}
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldColor,
              classes: {
                disabled: classes.disabledTextField,
                input: classes.textFieldInput,
              },
            }}
            InputLabelProps={{
              classes: {
                formControl: classes.textFieldLabelFormControl,
                shrink: classes.textFieldLabelShrink,
                root: classes.textFieldLabelRoot,
              },
            }}
            disabled
            classes={{
              root: classes.disabledTextField,
            }}
            variant="filled"
            value={accountProfileFields.email}
            className={classes.inputSpacing}
            onChange={event =>
              handleChangeAccountProfileField('email', event.target.value)
            }
          />
          <p className={`${classes.successMsg} ${classes.margin}`}>
            {nameChanged && t(`dashboard.${nameChanged}`)}
          </p>
        </div>
        <div className={classes.divider}></div>
        <p className={classes.paragraph}>{t('dashboard.changePassword')}</p>

        <div className={classes.inputWrapper}>
          <p className={classes.errorMsg}>
            {incorrectPassword && t(`dashboard.${incorrectPassword}`)}
          </p>
          <div className={classes.passwordField}>
            <TextField
              fullWidth
              label={t('dashboard.currentPassword')}
              InputProps={{
                autoComplete: 'new-password',
                disableUnderline: true,
                className: classes.textFieldColor,
                classes: {
                  input: classes.textFieldInput,
                },
              }}
              InputLabelProps={{
                classes: {
                  formControl: classes.textFieldLabelFormControl,
                  shrink: classes.textFieldLabelShrink,
                  root: classes.textFieldLabelRoot,
                },
              }}
              variant="filled"
              value={changePasswordFields.oldPassword}
              className={classes.inputSpacing}
              type={passwordShown ? 'text' : 'password'}
              onChange={event => {
                handleChangePasswordField('oldPassword', event.target.value)
                clearValidationMessages()
              }}
              onKeyPress={event =>
                !isChanging &&
                checkIfSubmittable(event, handleSaveDetailsChangesClick)
              }
            />
            <i
              className={classes.passVisibility}
              onClick={togglePasswordVisiblity}
            >
              {passwordShown ? eye : eyeSlash}
            </i>
          </div>
        </div>
        <div className={classes.inputWrapper}>
          <p className={classes.errorMsg}>
            {passwordErrorMsg && t(`dashboard.${passwordErrorMsg}`)}
          </p>
          <div className={classes.passwordField}>
            <TextField
              fullWidth
              label={t('dashboard.newPassword')}
              InputProps={{
                autoComplete: 'off',
                disableUnderline: true,
                className: classes.textFieldColor,
                classes: {
                  input: classes.textFieldInput,
                },
              }}
              InputLabelProps={{
                classes: {
                  formControl: classes.textFieldLabelFormControl,
                  shrink: classes.textFieldLabelShrink,
                  root: classes.textFieldLabelRoot,
                },
              }}
              variant="filled"
              value={changePasswordFields.password}
              className={classes.inputSpacing}
              type={password2Shown ? 'text' : 'password'}
              onChange={event => {
                handleChangePasswordField('password', event.target.value)
                clearValidationMessages()
              }}
              onKeyPress={event =>
                !isChanging &&
                checkIfSubmittable(event, handleSaveDetailsChangesClick)
              }
            />
            <i
              className={classes.passVisibility}
              onClick={togglePassword2Visiblity}
            >
              {password2Shown ? eye : eyeSlash}
            </i>
          </div>
        </div>
        <div className={classes.inputWrapper}>
          <p className={classes.errorMsg}>
            {confirmPasswordErrorMsg &&
              t(`dashboard.${confirmPasswordErrorMsg}`)}
          </p>
          <div className={classes.passwordField}>
            <TextField
              fullWidth
              label={t('dashboard.confirmPassword')}
              InputProps={{
                autoComplete: 'off',
                disableUnderline: true,
                className: classes.textFieldColor,
                classes: {
                  input: classes.textFieldInput,
                },
              }}
              InputLabelProps={{
                classes: {
                  formControl: classes.textFieldLabelFormControl,
                  shrink: classes.textFieldLabelShrink,
                  root: classes.textFieldLabelRoot,
                },
              }}
              variant="filled"
              value={changePasswordFields.confirmPassword}
              className={classes.inputSpacing}
              type={password3Shown ? 'text' : 'password'}
              onChange={event => {
                handleChangePasswordField('confirmPassword', event.target.value)
                clearValidationMessages()
              }}
              onKeyPress={event =>
                !isChanging &&
                checkIfSubmittable(event, handleSaveDetailsChangesClick)
              }
            />
            <i
              className={classes.passVisibility}
              onClick={togglePassword3Visiblity}
            >
              {password3Shown ? eye : eyeSlash}
            </i>
          </div>
          <p className={classes.successMsg}>
            {passwordChanged && t(`dashboard.${passwordChanged}`)}
          </p>
        </div>
        <div className={classes.inputWrapper}>
          <p className={classes.errorMsg}>
            {passwordMatchingError &&
              t(`forgotPassword.${passwordMatchingError}`)}
          </p>
          <div className={classes.divider}></div>
        </div>
        <div className={classes.switchContainer}>
          <IOSSwitch
            checked={isNewsletterChecked}
            onChange={handleNewslettersSwitch}
            name="newsletters"
          />
          <p className={classes.paragraph}>{t('join.subscribeToNewsletter')}</p>
        </div>
        <Button
          label={
            isDetailsSaved ? t('dashboard.saved') : t('dashboard.saveChanges')
          }
          onClick={handleSaveDetailsChangesClick}
          isSubmitting={isChanging}
          disabled={isChanging}
        />
      </section>
      {/* <p
        className={subtitleClasses}
        id="paypalSettings"
        ref={paypalSettingsRef}
      >
        {t('dashboard.payoutSettings')}
      </p> */}

      {/* <section className={classes.form}> */}
      {/* <p className={classes.paragraph}>
          {t('dashboard.payoutSettingsParagraph', {
            threshold: appState.settings?.paymentThreshold,
          })}
        </p>
        <div className={classes.paypalBox}>
          <img
            src={paypalIcon}
            alt={t('dashboard.paypal')}
            className={classes.paypalImg}
          />
          {payment.isEditVisible && (
            <button className={classes.editPaypalButton} onClick={handleOnEdit}>
              <Tooltip title={t('shared.edit')} arrow>
                <img src={editIcon} alt={t('shared.edit')} />
              </Tooltip>
            </button>
          )}
        </div>
        <div className={classes.inputWrapper}>
          <p className={classes.paymentErrorMsg}>
            {payment.emailErrorMsg && t(`dashboard.${payment.emailErrorMsg}`)}
          </p>
          <TextField
            fullWidth
            label={t('shared.emailAddress')}
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldColor,
              classes: {
                disabled: classes.disabledTextField,
                input: classes.textFieldInput,
              },
            }}
            InputLabelProps={{
              classes: {
                formControl: classes.textFieldLabelFormControl,
                shrink: classes.textFieldLabelShrink,
                root: classes.textFieldLabelRoot,
              },
            }}
            classes={{
              root: payment.isPaymentDisabled && classes.disabledTextField,
            }}
            variant="filled"
            value={payment.paypalEmail}
            className={classes.paymentInputSpacing}
            onChange={event => {
              setPayment({
                ...payment,
                emailErrorMsg: '',
                defaultPaymentErrorMsg: '',
                paypalEmail: event.target.value,
              })
            }}
            onKeyPress={event =>
              !payment.isLoading && checkIfSubmittable(event, handlePaypalSave)
            }
            disabled={payment.isPaymentDisabled}
          /> */}
      {/* <PaymentRadioInput
            onChange={handleDefaultPayment}
            checked={payment.defaultPayment === 'PAYPAL'}
            id="PAYPAL"
            label="make PayPal my default payment method"
            disabled={payment.isPaymentDisabled}
          /> */}
      {/* </div> */}
      {/* <div className={classes.paypalBox}>
          <img src={venmoIcon} alt="venmo" className={classes.venmoImg} />
        </div>
        <div className={classes.inputWrapper}>
          <p className={classes.paymentErrorMsg}>{payment.venmoErrorMsg}</p>
           <Input
            fullWidth
            disableUnderline={true}
            // variant="filled"
            placeholder="venmo account phone number"
            inputProps={{
              disableUnderline: true,
              className: classes.textFieldColor,
              classes: {
                disabled: classes.disabledTextField,
                input: classes.textFieldInput,
              },
            }}
            value={payment.venmoAccount}
            onChange={event => {
              setIsChanged(true)
              setPayment({
                ...payment,
                showSaved: false,
                venmoErrorMsg: '',
                defaultPaymentErrorMsg: '',
                venmoAccount: event.target.value,
              })
            }}
            onKeyPress={event =>
              !payment.isLoading && checkIfSubmittable(event, handlePaypalSave)
            }
            inputComponent={TextMask}
          /> 
          <MUInput
            fullWidth
            disableUnderline={true}
            placeholder="venmo number"
            inputProps={{
              disableUnderline: true,
              className: classes.textFieldColor,
              classes: {
                disabled: classes.disabledTextField,
                input: classes.textFieldInput,
              },
            }}
            value={payment.venmoAccount}
            onChange={event => {
              setPayment({
                ...payment,
                venmoErrorMsg: '',
                defaultPaymentErrorMsg: '',
                venmoAccount: event.target.value,
              })
            }}
            onKeyPress={event =>
              !payment.isLoading && checkIfSubmittable(event, handlePaypalSave)
            }
            disabled={payment.isPaymentDisabled}
            inputComponent={TextMask}
          />
          <PaymentRadioInput
            onChange={handleDefaultPayment}
            checked={payment.defaultPayment === 'VENMO'}
            id="VENMO"
            label="make Venmo my default payment method"
            disabled={payment.isPaymentDisabled}
          />
        </div> */}
      {/* <Box mt={4} style={{ position: 'relative' }}>
          <p className={classes.errorMsg}>{payment.defaultPaymentErrorMsg}</p>
        </Box>
        <Button
          label={
            payment.isPaymentDisabled
              ? t('dashboard.saved')
              : t('dashboard.saveChanges')
          }
          onClick={handlePaypalSave}
          isSubmitting={payment.isLoading}
          disabled={payment.isLoading || payment.isPaymentDisabled}
        /> */}
      {/* </section> */}

      {/* connected accounts section */}
      {/* <div className={subtitleClasses}>{t('dashboard.connectedAccounts')}</div>
      <section className={classes.form}>
        <p>{t('dashboard.connectNewAccount')}</p>
        <div>
          <p className={classes.errorMsg}>{socialErrorMsg}</p>
          <div className={classes.inputWrapper}>
            <Input
              placeholder="username"
              value={
                socials.instagramUrl
                  .split('/')
                  .filter(a => a)
                  .reverse()[0]
              }
              customStyles={classes.socialInput}
              onChange={event => {
                setSocials({
                  ...socials,
                  instagramUrl: event.target.value
                    ? socialMedia.instagram.label.replace(
                        'username',
                        event.target.value
                      )
                    : '',
                })
              }}
            />
            <div className={classes.socialImgBox}>
              <img src={socialMedia.instagram.logo} alt="logo" />
            </div>
          </div>
          {errors.instagram && (
            <div className={classes.connectError}>{errors.instagram}</div>
          )}
          <Button
            customStyle={classes.socialBtn}
            label={
              socials.instagramInactive === false
                ? t('dashboard.accountConnected')
                : t('dashboard.connectAccount')
            }
            onClick={() => {
              handleConntected(
                {
                  ...socials,
                  instagramInactive: false,
                },
                'instagram'
              )
            }}
          />
        </div>
        <section className={classes.formComing}>
          {socialMediaArray.map(e => (
            <SocialMediaInput logo={e.logo} label={e.label} key={e.label} />
          ))}
        </section>
        <Button
          label={t('dashboard.saveChanges')}
          onClick={handleChangeSocial}
          isSubmitting={isSocialLoading}
          disabled={isSocialLoading}
        />
      </section> */}
    </div>
  )
}

const UsernameTextField = ({
  label,
  classes,
  style,
  value,
  onChange,
  onKeyPress,
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      InputProps={{
        autoComplete: 'off',
        disableUnderline: true,
        className: classes.textFieldColor,
        classes: {
          input: classes.textFieldInput,
        },
      }}
      InputLabelProps={{
        classes: {
          formControl: classes.textFieldLabelFormControl,
          shrink: classes.textFieldLabelShrink,
          root: classes.textFieldLabelRoot,
        },
      }}
      required
      variant="filled"
      style={style}
      value={value}
      className={classes.inputSpacing}
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  )
}

const UsernamePopup = ({ alt, classes, open, children }) => {
  return (
    <Popup
      title={
        <>
          <img src={birdFace} alt={alt} className={classes.imageContainer} />
          <div className={classes.textContainer}>enter username</div>
        </>
      }
      placement="left"
      open={open}
      overrideClasses={{ popper: classes.enterUsernamePopup }}
    >
      {children}
    </Popup>
  )
}

export default withTranslation()(SettingsTab)
