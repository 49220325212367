import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Typography, Grid } from '@material-ui/core'
import { navigate } from 'gatsby'
import axios from '../../axios'
import { ENDPOINTS } from '../../constants'
import CHRArrowButton from './../../components/arrowButton'
import Card from '../trendingCard'
import { useAppState } from '../../appState'
import { styles } from './styles'

const TrendingProducts = ({ t }) => {
  const classes = styles()
  const [appState] = useAppState()
  const [homeProducts, setHomeProducts] = useState([])
  const [error, setError] = useState('')

  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  const getTrendingProducts = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(ENDPOINTS.trending)
      const limitedProducts = data.slice(0, 12)
      limitedProducts.forEach((p: any) => {
        p.data.product_id = p.product_id
        p.data.trendingId = p.id
      })
      setHomeProducts(limitedProducts)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const updateProducts = (productId: string, shoppingBoardId: number) => {
    setHomeProducts((items: any) => {
      const i = items.findIndex(
        (e: any) => e.id === productId || e.product_id === productId
      )
      return [
        ...items.slice(0, i),
        {
          ...items[i],
          data: { ...items[i].data, shoppingBoardId },
        },
        ...items.slice(i + 1),
      ]
    })
  }

  useEffect(() => {
    getTrendingProducts()
  }, [])

  return (
    !error && (
      <div className={classes.trendingContainer}>
        {/* <Typography
          variant="h1"
          className={classes.mobileEdgeSpace + ' ' + classes.trendingHeading}
        >
          {t('memberHome.trending')}
        </Typography> */}
        <span
          className={classes.mobileEdgeSpace + ' ' + classes.trendingHeading}
        >
          {t('memberHome.trending')}
        </span>
        {/* <Typography
          variant="subtitle1"
          className={classes.mobileEdgeSpace2 + ' ' + classes.trendingSubtitle}
        >
          {t('memberHome.trendingSubtitle')}
        </Typography> */}

        <Typography
          variant="h1"
          className={classes.trendingSubtitle + ' ' + classes.mobileEdgeSpace2}
        >
          {t('seo.landingPage.h1')}
        </Typography>
        <Grid container spacing={deviceType() === 'mobile' ? 5 : 3}>
          {homeProducts.map((product: any) => (
            <Grid item lg={3} md={4} sm={4} xs={6} key={product.id}>
              <Card
                updateProducts={updateProducts}
                productInfo={product.data}
                userId={appState.userId}
                shareable={Boolean(product.data.product_id)}
              />
            </Grid>
          ))}
        </Grid>
        <div className={classes.CHRbArrowButton}>
          <CHRArrowButton
            label={appState.username ? 'see all' : 'shop'}
            onClick={() => navigate('/shop')}
            hoverEffects={true}
          />
        </div>
      </div>
    )
  )
}

export default withTranslation()(TrendingProducts)
