import { makeStyles } from '@material-ui/core/styles'
import { COLORS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
  },
  container: {
    width: '100%',
    maxWidth: 460,
    margin: '0px auto',
  },
  pictureIsOptional: {
    color: COLORS.ashGrey,
  },
  uploadPicture: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 40,
  },
  uploadPictureContainer: {
    display: 'block',
    width: '30%',
    maxWidth: 100,
    borderRadius: '100%',
    overflow: 'hidden',
  },
  uploadPictureAvatar: {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '100%',
    background: 'black',
  },
  birdAvatar: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 53,
    margin: 'auto',
    display: 'block',
    position: 'absolute',
  },
  uploadPictureText: {
    marginLeft: 20,
    width: '70%',
  },
  tapText: {
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
  },
  clickText: {
    display: 'none',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'inline',
    },
  },
  clickHere: {
    cursor: 'pointer',
  },
  inputContainer: {
    marginBottom: 25,
    position: 'relative',
  },
  buttonContainer: {
    marginTop: 40,
    position: 'relative',
  },
  errorMsg: {
    color: COLORS.dangerRed,
    position: 'absolute',
    left: 0,
    top: -20,
    fontSize: '0.875rem',
    margin: 0,
  },
  successMsg: {
    color: COLORS.successGreen,
    position: 'absolute',
    left: 0,
    bottom: -55,
    fontSize: '0.875rem',
    margin: 0,
  },
  clickHereToUpload: {
    display: 'block',
    width: '100%',
    position: 'relative',
  },
  spinner: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadError: {
    position: 'absolute',
    top: 'auto',
    bottom: -22.5,
  },
}))
