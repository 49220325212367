import React from 'react'
import { styles } from './styles'
import { Typography } from '@material-ui/core'

const PaymentRadioInput = ({
  checked,
  onChange,
  id,
  label,
  disabled,
}: {
  checked: boolean
  onChange: Function
  id: string
  label: string
  disabled: boolean
}) => {
  const classes = styles({ disabled })
  return (
    <div className={classes.container}>
      <div className={classes.round}>
        <input
          type="radio"
          id={id}
          name="payment"
          value={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <label htmlFor={id}></label>
      </div>
      <label
        htmlFor={id}
        className={`${checked && !disabled ? '' : classes.disabled}`}
      >
        <Typography variant="subtitle1" className={classes.label}>
          {label}
        </Typography>
      </label>
    </div>
  )
}

export default PaymentRadioInput
