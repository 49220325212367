import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(theme => ({
  blogImage: {
    width: '100%',
    // minWidth: 706,
    height: '100%',
    objectFit: 'cover',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      height: '100%',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: '100%',
      height: 325,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '100vw',
      height: '285px',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      // maxHeight: '231px',
    },
  },
  blogImage2: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      height: 353,
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: '100%',
      height: 325,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '100vw',
      height: '285px',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
    },
  },
  blogSubtitle: {
    color: COLORS.lightGrey,
    paddingTop: 20,
    marginBottom: 0,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      marginBottom: 15,
    },
  },
  mobileEdgeSpace: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: '30px',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: '30px',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '20px',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  joinLinkSpan: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: '5px',
    marginLeft: '5px',
  },
  subtitles: {
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      padding: '12px 24px',
      lineHeight: 1.5,
    },
  },
}))
