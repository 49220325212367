import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  TableBody,
  Collapse,
  Box,
  Typography,
  withStyles,
  CircularProgress,
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import { Link } from 'gatsby'
import axios from '../../axios'
import { COLORS, ENDPOINTS } from '../../constants'
import * as moment from 'moment'
import { styles } from './styles'
import { useAppState } from '../../appState'

interface CollapsibleTableRowProps {
  row: any
  customStyle: any
}

const StyledTableRow = withStyles(theme => ({
  root: {
    '& td': {
      maxWidth: 250,
    },
  },
}))(TableRow)

const collapsibleTableRow = ({
  row,
  customStyle,
}: CollapsibleTableRowProps) => {
  const classes = styles()
  const [appState] = useAppState()
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [errorMsg, setErrorMsg] = useState(null)

  useEffect(() => {
    if (open) getUserRecruits()
  }, [open])

  const getUserRecruits = async () => {
    setIsLoading(true)
    try {
      const {
        data: { data },
      } = await axios.post(ENDPOINTS.getSignedByYourRecruits, {
        id: row.id,
      })
      setTableData(data)
      setIsLoading(false)
    } catch (e) {
      setErrorMsg(
        e?.response?.data?.message || t('messages.somethingWentWrong')
      )
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <StyledTableRow className={customStyle}>
        <TableCell>
          <Link
            to={`/s/${row.username}`}
            style={{ color: COLORS.black }}
            target="_blank"
          >
            {row.username || row.name}
          </Link>
        </TableCell>
        <TableCell>{moment(row.created_at).format('MM/DD/YY')}</TableCell>
        <TableCell>{row.used_chirpyest}</TableCell>
        <TableCell>{row.paid_bonus}</TableCell>
        <TableCell width="5%">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {isLoading ? (
              <div className={classes.loading}>
                <CircularProgress style={{ color: COLORS.black }} size={40} />
              </div>
            ) : errorMsg ? (
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                style={{ color: COLORS.dangerRed }}
              >
                {errorMsg}
              </Typography>
            ) : (
              <Box sx={{ margin: 1 }}>
                {!tableData.length ? (
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    component="div"
                    style={{ textAlign: 'center', padding: 32 }}
                  >
                    user did not recruit anyone
                  </Typography>
                ) : (
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          <Typography variant="subtitle1">user</Typography>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          <Typography variant="subtitle1">joined</Typography>
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          <Typography variant="subtitle1">
                            used chirpyest
                          </Typography>
                        </TableCell>
                        {appState?.userProfile?.isReferralBonus && (
                          <TableCell className={classes.tableHeader}>
                            <Typography variant="subtitle1">
                              referral bonus paid
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((userData: any) => (
                        <TableRow key={`${userData.id}`}>
                          <TableCell>
                            <Link
                              to={
                                userData.username
                                  ? `/s/${userData.username}`
                                  : '#'
                              }
                              style={{ color: COLORS.black }}
                              target="_blank"
                            >
                              {userData.username || userData.name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {moment(userData.created_at).format('MM/DD/YY')}
                          </TableCell>
                          <TableCell>{userData.used_chirpyest}</TableCell>
                          {appState?.userProfile?.isReferralBonus && (
                            <TableCell>{userData.paid_bonus}</TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default collapsibleTableRow
