import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS, WIDTH_DIMENSIONS } from '../../constants'

export const styles = makeStyles(theme => ({
  mainTitle: {
    maxWidth: 800,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  desktop: {
    display: 'block',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      display: 'none',
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
    },
  },
  buttonContainer: {
    width: '80%',
    maxWidth: 460,
    margin: '0px auto',
  },
  mobile: {
    display: 'none',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
    },
  },
  trendingSection: {
    ['& > div']: {
      width: '100%',
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      ['& > div']: {
        width: '90%',
      },
    },
  },
  trendingTitle: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '100%',
    },
  },
  rightChildArticle: {
    paddingTop: 35,
    position: 'relative',
  },
  rightChildrenArrowButton: {
    position: 'absolute',
    right: 0,
    width: '270px',
  },
  titleContainer: {
    width: '95%',
    marginLeft: 'auto',
    marginRight: 0,
  },
  exploreButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '95%',
    ['& > div > p']: {
      textTransform: 'lowercase',
    },
  },
  howItWorksSection: {
    width: '100%',
  },
  trendingButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '92%',
  },
  noMargins: {
    margin: 0,
  },
  quote: {
    fontStyle: 'italic',
  },

  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    mainTitle: {
      width: '85%',
    },
    title: {
      fontSize: '3.75rem' /*equals 60px*/,
    },
    rightChildArticle: {
      paddingTop: 0,
      ['& blockquote']: {
        marginRight: 0,
        marginLeft: 0,
      },
      width: '90%',
      marginLeft: 'auto',
    },
    rightChildrenTitle: {
      marginTop: 0,
    },
    titleContainer: {
      width: '100%',
    },
    postsSection: {
      width: '92.5%',
      marginLeft: 'auto',
      marginRight: 0,
    },
    postsList: {
      display: 'flex',
      flexWrap: 'wrap',
      [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
        justifyContent: 'space-between',
      },
    },
    exploreButton: {
      justifyContent: 'flex-end',
      width: '96.5%',
      marginRight: 'auto',
      marginLeft: 0,
    },
    howItWorksSection: {
      width: '85%',
      margin: '0 auto',
    },
    trendingButtonContainer: {
      width: '100%',
    },
    heroContainer: {
      maxWidth: 960,
      margin: 'auto auto 50px auto',
      fontFamily: FONTS.Graphik.GraphikLight,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& p': {
        textAlign: 'center',
        '& span': {
          fontWeight: 'bold',
        },
      },
    },
    chromeExtensionInfo: {
      display: 'none',
      [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
        display: 'block',
        marginTop: 35,
      },
    },
    chromeReviews: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
    },
    reviewNumbers: {
      margin: 0,
      marginLeft: 10,
    },
    millionMember: {
      margin: 0,
      marginTop: 5,
    },
  },
  howItWorksLinkContainer: {
    textAlign: 'center',
    marginBottom: 30,
  },
  howItWorksLink: {
    textDecoration: 'none',
    color: theme.palette.common.black,
    fontSize: '1.75rem',
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    alignContent: 'center',
    borderBottom: '1px solid',
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
  },
  underLinedText: {
    cursor: 'pointer',
    color: theme.palette.common.black,
  },
  sectionContainer: {
    width: '100%',
    overflow: 'hidden',
    paddingTop: 100,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      paddingTop: 120,
    },
  },
  feedImg: {
    width: '100%',
    maxWidth: 706,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      padding: 0,
    },
  },
  feedTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  feedTitleHeart: {
    width: 50,
    height: 53,
    paddingLeft: 20,
    marginBottom: 25,
  },
  mobileEdgeSpace: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      fontSize: '2.5rem',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  CHRblogButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
    },
  },
  brandsList: {
    paddingTop: 100,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {},
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      paddingTop: 120,
    },
  },
  mobileBackground: {
    width: '100%',
    margin: '0 auto',
    maxWidth: WIDTH_DIMENSIONS.max,
    padding: '40px 0 154px',
    backgroundColor: COLORS.whiteStain,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      padding: '40px 5% 154px',
    },
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      backgroundColor: COLORS.white,
      padding: '0px 0px',
      width: '95%',
    },
  },
  brandsListSection: {
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      // display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  brandsListTitle: {
    width: '95%',
    margin: '0 auto 30px 20px',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      width: '70%',
      margin: '0 auto 30px 20px',
    },
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      width: '30%',
      margin: '0 auto 30px 10px',
    },
  },
  addChirpyestBtn: {
    position: 'absolute',
    bottom: -95,
    right: '5%',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      position: 'static',
      display: 'block',
    },
  },
  brandsListContainer: {
    width: '100%',
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      width: '65%',
    },
  },
  trendingContainer: {
    width: '100%',
    paddingTop: 100,
    overflow: 'hidden',
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      paddingTop: 80,
    },
  },
  trendingHeading: {
    marginBottom: 10,
  },
  trendingSubtitle: {
    marginBottom: 25,
  },
}))
