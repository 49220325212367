import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Typography, Grid } from '@material-ui/core'
import { navigate } from 'gatsby'
import CHRArrowButton from '../../../components/arrowButton'
import Card from '../topProductCard'
import { styles } from './styles'
import { ENDPOINTS } from '../../../constants'
import { useAppState } from '../../../appState'
import axios from '../../../axios'
import heart from './../../../assets/images/simple-heart.svg'

const TopProducts = ({ t }) => {
  const classes = styles()

  const [appState] = useAppState()
  const [homeProducts, setHomeProducts] = useState([])
  const [topProducts, setTopProducts] = useState([])
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)

  const [error, setError] = useState('')
  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }
  const getTopProducts = async () => {
    try {
      let endIndex
      if (deviceType() === 'mobile') {
        endIndex = 4
      } else if (deviceType() === 'tablet') {
        endIndex = 18
      } else {
        endIndex = 6
      }
      setEnd(endIndex)
      const {
        data: { data },
      } = await axios.get(ENDPOINTS.topProducts)
      const limitedProducts = data.slice(0, 18)
      limitedProducts.forEach((p: any) => {
        p.data.product_id = p.product_id
        p.data.trendingId = p.id
      })
      setTopProducts(limitedProducts)
      setHomeProducts(limitedProducts.slice(start, endIndex))
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const getUpdatedTopProducts = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(ENDPOINTS.topProducts)
      const limitedProducts = data.slice(0, 18)
      limitedProducts.forEach((p: any) => {
        p.data.product_id = p.product_id
        p.data.trendingId = p.id
      })
      setTopProducts(limitedProducts)
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  function getElements(arr, s, e) {
    const arrNew = []
    for (let i = s; i <= e; i++) {
      let index = i
      if (i < 0) {
        index = arr.length + i
      }
      arrNew.push(arr[index])
    }
    return arrNew
  }

  const forwardArrowClicked = () => {
    let startIndex = start + 4
    let endIndex
    if (startIndex === topProducts.length - 1) {
      startIndex = -1
    } else if (startIndex > topProducts.length - 1) {
      startIndex = startIndex - topProducts.length - 1
    }
    startIndex = startIndex % topProducts.length
    endIndex = startIndex + 5
    if (endIndex > topProducts.length - 1) {
      endIndex = endIndex - topProducts.length
      startIndex = endIndex - 5
    }
    setHomeProducts(getElements(topProducts, startIndex, endIndex))
    setStart(startIndex)
    setEnd(endIndex)
    getUpdatedTopProducts()
  }

  const backArrowClicked = () => {
    let startIndex = start - 4
    startIndex = startIndex % topProducts.length
    const endIndex = startIndex + 5
    setHomeProducts(getElements(topProducts, startIndex, endIndex))
    setStart(startIndex)
    setEnd(endIndex)
    getUpdatedTopProducts()
  }

  const updateProducts = (productId: string, shoppingBoardId: number) => {
    setHomeProducts((items: any) => {
      const i = items.findIndex(
        (e: any) => e.id === productId || e.product_id === productId
      )
      return [
        ...items.slice(0, i),
        {
          ...items[i],
          data: { ...items[i].data, shoppingBoardId },
        },
        ...items.slice(i + 1),
      ]
    })
  }

  useEffect(() => {
    getTopProducts()
  }, [])

  return (
    !error && (
      <>
        <div className={classes.trendingContainer}>
          <Typography
            variant="h1"
            className={classes.mobileEdgeSpace + ' ' + classes.trendingHeading}
          >
            {t('memberHome.topProducts')}
          </Typography>
          <Typography variant="subtitle1" className={classes.trendingSubtitle}>
            {t('memberHome.topProductsSubtitle')}
            <img
              src={heart}
              className={classes.heart}
              alt="heart-icon"
              width="22"
              height="20"
            />
          </Typography>
        </div>
        {deviceType() === 'mobile' && (
          <>
            <div className={classes.gridContainer}>
              <Grid
                container
                spacing={5}
                style={{ width: '100%', margin: '0' }}
              >
                {homeProducts.map((product: any) => (
                  <Grid item lg={3} md={4} sm={4} xs={6} key={product.id}>
                    <Card
                      updateProducts={updateProducts}
                      productInfo={product.data}
                      userId={appState.userId}
                      shareable={Boolean(product.data.product_id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>

            <div className={classes.CHRbArrowButton}>
              <CHRArrowButton
                label="see all products"
                onClick={() => navigate('/shop')}
                hoverEffects={true}
              />
            </div>
          </>
        )}
        {deviceType() === 'tablet' && (
          <>
            <div className={classes.tabletMode}>
              <Grid
                container
                spacing={3}
                style={{
                  flexWrap: 'nowrap',
                  marginBottom: '10px',
                }}
              >
                {homeProducts.map((product: any) => (
                  <Grid
                    item
                    lg={2}
                    md={3}
                    sm={6}
                    xs={6}
                    key={product.id}
                    style={{ minWidth: '229.5px' }}
                  >
                    <Card
                      updateProducts={updateProducts}
                      productInfo={product.data}
                      userId={appState.userId}
                      shareable={Boolean(product.data.product_id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>

            <div className={classes.trendingContainer3}>
              <div className={classes.CHRbArrowButton}>
                <CHRArrowButton
                  label="see all products"
                  onClick={() => navigate('/shop')}
                  hoverEffects={true}
                />
              </div>
            </div>
          </>
        )}
        {deviceType() === 'desktop' && (
          <div className={classes.trendingContainer2}>
            <div className={classes.topProductsContainer}>
              <div className={classes.arrowMaxHeight}>
                <CHRArrowButton
                  isLeftArrow
                  label=""
                  onClick={backArrowClicked}
                  hoverEffects={true}
                />
              </div>

              <Grid
                container
                spacing={3}
                style={{ flexWrap: 'nowrap', overflow: 'hidden' }}
              >
                {homeProducts.map((product: any) => (
                  <Grid
                    item
                    lg={2}
                    md={3}
                    sm={6}
                    xs={6}
                    key={product.id}
                    style={{ minWidth: '229.5px' }}
                  >
                    <Card
                      updateProducts={updateProducts}
                      productInfo={product.data}
                      userId={appState.userId}
                      shareable={Boolean(product.data.product_id)}
                    />
                  </Grid>
                ))}
              </Grid>
              <div className={classes.arrowMaxHeight}>
                <CHRArrowButton
                  label=""
                  onClick={forwardArrowClicked}
                  hoverEffects={true}
                />
              </div>
            </div>
            <div className={classes.trendingContainer3}>
              <div className={classes.CHRbArrowButton}>
                <CHRArrowButton
                  label="see all products"
                  onClick={() => navigate('/shop')}
                  hoverEffects={true}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  )
}

export default withTranslation()(TopProducts)
