import React from 'react'
import { Typography, Grid, Link } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { styles } from './styles'
import { ROUTES } from '../../../constants'
import { navigate } from 'gatsby'
interface BlogItemProps {
  t: TFunction
  blogImage: any
  blogTitle: any
  link?: any
  isBlog: string
  linkUser?: any
  add?: any
  isExtension?: boolean
  user?: any
}

const BlogItem = ({
  t,
  blogImage,
  blogTitle,
  link,
  isBlog,
  linkUser = '',
  add,
  isExtension,
  user,
}: BlogItemProps) => {
  const classes = styles()
  return (
    <div>
      {isBlog == 'blog' && (
        <a href={link} target="_blank">
          <img
            src={blogImage}
            alt={t('imageAlts.placeholder')}
            className={classes.blogImage2}
            loading="lazy"
          />
        </a>
      )}

      {isBlog == 'blog' && (
        <a
          href={link}
          target="_blank"
          style={{ color: 'black', textDecoration: 'none' }}
        >
          <Typography
            variant="h2"
            className={classes.mobileEdgeSpace}
            style={{ paddingTop: '20px' }}
          >
            {blogTitle}
          </Typography>
        </a>
      )}

      {isBlog == 'categoriesSection' && (
        <img
          src={blogImage}
          alt={t('imageAlts.placeholder')}
          className={classes.blogImage + ' ' + classes.pointer}
          loading="lazy"
          onClick={() => navigate(link)}
        />
      )}

      {isBlog == 'categoriesSection' && (
        <Typography
          style={{ paddingTop: '15px' }}
          variant="subtitle1"
          className={classes.pointer + ' ' + classes.subtitles}
          onClick={() => navigate(link)}
        >
          {blogTitle}
        </Typography>
      )}

      {isBlog == 'topSection' && (
        <img
          src={blogImage}
          alt={t('imageAlts.placeholder')}
          className={classes.blogImage + ' ' + classes.pointer}
          loading="lazy"
          onClick={() => {
            if (link == 'ShopEarnCashback') {
              navigate(ROUTES.shop)
            } else if (link == 'CurateProducts') {
              if (user?.username) {
                navigate(ROUTES.mainBoard.replace(':userName', user.username))
              } else {
                window.localStorage.setItem('redirectToBoardPage', 'true')
                navigate(linkUser)
              }
            } else if (link == 'UseBrowserExtension') {
              navigate(ROUTES.brands)
            }
          }}
        />
      )}

      {isBlog == 'topSection' && link == 'ShopEarnCashback' && (
        <Typography
          style={{ paddingTop: '15px' }}
          variant="subtitle1"
          className={classes.subtitles}
        >
          shop directly on the sites of
          <span
            className={classes.joinLinkSpan}
            onClick={() => navigate(ROUTES.brands)}
          >
            hundreds of brands
          </span>
          OR search for
          <span
            className={classes.joinLinkSpan}
            onClick={() => navigate(ROUTES.shop)}
          >
            products here on chirpyest
          </span>
        </Typography>
      )}

      {isBlog == 'topSection' && link == 'CurateProducts' && (
        <Typography
          style={{ paddingTop: '15px' }}
          variant="subtitle1"
          className={classes.subtitles}
        >
          <span
            style={{ marginLeft: '0px' }}
            className={classes.joinLinkSpan}
            onClick={() => {
              if (user?.username) {
                navigate(ROUTES.mainBoard.replace(':userName', user.username))
              } else {
                window.localStorage.setItem('redirectToBoardPage', 'true')
                navigate(linkUser)
              }
            }}
          >
            create your personal shopping board
          </span>
          by saving items you love. either shop or share your board and earn
          cash back
        </Typography>
      )}
      {isBlog == 'topSection' && link == 'UseBrowserExtension' && (
        <Typography
          style={{ paddingTop: '15px' }}
          variant="subtitle1"
          className={classes.subtitles}
        >
          with the browser extension, you can shop directly on 100s of retailer
          websites and earn cash back.
          {!isExtension && (
            <span className={classes.joinLinkSpan} onClick={add}>
              add the extension
            </span>
          )}
          {isExtension && (
            <span
              className={classes.joinLinkSpan}
              onClick={() => navigate(ROUTES.brands)}
            >
              use the extension
            </span>
          )}
        </Typography>
      )}
    </div>
  )
}

export default withTranslation()(BlogItem)
