import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { styles } from './styles'
import ArrowLeft from '../../assets/images/arrow-left.svg'
import ArrowRightLight from '../../assets/images/arrow-right-light.svg'
import ArrowRight from '../../assets/images/arrow-back.svg'
import { Typography } from '@material-ui/core'

const MonthNavigate = ({ t, onMonthChange }) => {
  const classes = styles()
  const currentDateObj = new Date()
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const initialMonth = +currentDateObj.getMonth()
  const [currentMonth, setCurrentMonth] = useState(initialMonth)
  const initialYear = +currentDateObj.getFullYear()
  const [currentYear, setCurrentYear] = useState(initialYear)

  useEffect(() => {
    onMonthChange({ currentMonth, currentYear })
  }, [currentMonth, currentYear])

  const onForward = () => {
    if (currentMonth === 11) {
      setCurrentYear(currentYear + 1)
      setCurrentMonth(0)
    } else {
      setCurrentMonth(currentMonth + 1)
    }
  }
  const onBack = () => {
    if (currentMonth === 0) {
      setCurrentYear(currentYear - 1)
      setCurrentMonth(11)
    } else {
      setCurrentMonth(currentMonth - 1)
    }
  }

  return (
    <div className={classes.container}>
      <img
        className={classes.arrow}
        src={ArrowLeft}
        onClick={onBack}
        alt="Left Arrow"
      />
      <Typography
        variant="h3"
        style={{ marginBottom: 0, paddingLeft: 20, paddingRight: 20 }}
      >
        {monthNames[currentMonth]}
      </Typography>
      {currentMonth === initialMonth && currentYear === initialYear ? (
        <img
          src={ArrowRightLight}
          className={classes.arrow}
          alt="Right Arrow"
        />
      ) : (
        <img
          src={ArrowRight}
          className={classes.arrow}
          onClick={onForward}
          alt={t('imageAlts.rightArrow')}
        />
      )}
    </div>
  )
}

export default withTranslation()(MonthNavigate)
