import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  trendingHeading: {
    marginBottom: 10,
  },
  trendingSubtitle: {
    marginBottom: 25,
  },
  heart: {
    position: 'absolute',
    marginLeft: '14px',
  },
  trendingContainer: {
    width: '100%',
    paddingTop: 100,
    overflow: 'hidden',
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      paddingTop: 50,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      paddingTop: 50,
    },
  },
  trendingContainer2: {
    width: '100%',
    // paddingTop: 100,
    overflow: 'hidden',
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      // width: '90%',
      margin: '0 auto',
      // paddingTop: 80,
    },
  },
  trendingContainer3: {
    width: '100%',
    paddingTop: 25,
    overflow: 'hidden',
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      // paddingTop: 80,
    },
  },
  mobileEdgeSpace: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      fontSize: '2.5rem',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '3.75rem',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      // paddingLeft: 20,
      // paddingRight: 20,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '2.25rem',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  CHRbArrowButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
    },
  },
  topProductsContainer: {
    display: 'flex',
    ['& > div:first-child']: {
      marginRight: 13,
      ['& > button:last-child img']: {
        marginLeft: '3px !important',
        marginRight: '6px !important',
      },
    },
    ['& > div:last-child']: {
      marginLeft: 14,
      ['& > button:first-child img']: {
        marginLeft: '10px !important',
        marginRight: '5px !important',
      },
    },
  },
  arrowMaxHeight: {
    minWidth: 70,
    minHeight: 222,
    maxHeight: 222,
    display: 'flex',
    alignItems: 'center',
    background: '#FAFBFD',
  },
  card: {
    width: 250,
    height: 250,
    position: 'relative',
    color: theme.palette.common.black,
    cursor: 'pointer',
    backgroundColor: COLORS.whiteStain,
    ['&:hover']: {
      '& p': {
        color: theme.palette.common.black,
      },
    },
  },
  whiteCard: {
    backgroundColor: COLORS.white,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      backgroundColor: COLORS.whiteStain,
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      backgroundColor: COLORS.whiteStain,
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      backgroundColor: COLORS.whiteStain,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      backgroundColor: COLORS.whiteStain,
    },
  },
  cashBackText: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    color: COLORS.lightGrey,
    fontSize: '0.8rem',
    letterSpacing: '0.5px',
    margin: 0,
    position: 'absolute',
    bottom: '3%',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    transition: '0.5s',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      color: COLORS.black,
    },
  },
  cashBackTextActive: {},
  logo: {
    position: 'absolute',
    top: '10%',
    right: 0,
    bottom: 0,
    left: '15%',
    width: '70%',
    height: '70%',
    objectFit: 'contain',
  },
  mobileEdgeSpace2: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      // paddingLeft: 20,
      // paddingRight: 20,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  tabletMode: {
    ['& > .MuiGrid-container']: {
      overflow: 'auto',
      scrollbarWidth: 'none',
      MsOverflowStyle: 'none',
    },
    ['& > .MuiGrid-container::-webkit-scrollbar']: {
      display: 'none',
    },
  },
}))
