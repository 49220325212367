import { makeStyles } from '@material-ui/core/styles'
import { FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  tableHeader: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '2rem',
    lineHeight: 1.3,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '1.25rem',
    },
  },
  loading: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
