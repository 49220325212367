import React, { useCallback, useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import errorImg from '../../../assets/images/error-image.svg'
const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  spinner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorImageWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FAFBFD',
  },
  errorImage: {
    width: 80,
    height: 80,
  },
  errorClass: {
    objectFit: 'none',
  },
})

interface ImageProps {
  src?: string
  alt?: string
  onClick?: Function
  errStyle?: string
  className?: string
  title?: string
  draggable?: boolean
  style?: any
}

export default ({
  src,
  alt,
  className,
  errStyle,
  onClick,
  ...props
}: ImageProps) => {
  const classes = useStyles()
  const [imgSrc, setSrc] = useState(src)
  const [isLoaded, setIsloaded] = useState(false)

  const onLoad = useCallback(() => {
    setIsloaded(true)
  }, [isLoaded])

  const onError = useCallback(() => {
    setSrc(errorImg)
    setIsloaded(true)
  }, [errorImg])

  useEffect(() => {
    if (src && imgSrc !== src) setSrc(src)
    const img = new Image()
    img.src = src as string
    img.addEventListener('load', onLoad)
    img.addEventListener('error', onError)
    return () => {
      img.removeEventListener('load', onLoad)
      img.removeEventListener('error', onError)
    }
  }, [src, onLoad, onError])

  if (!isLoaded)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    )
  return (
    <div
      className={
        classes.container +
        ' ' +
        (imgSrc === errorImg ? classes.errorImageWrapper : '')
      }
      onClick={e => onClick?.(e)}
    >
      <img
        {...props}
        alt={alt}
        height="454"
        width="440"
        src={imgSrc}
        className={`${className} ${
          imgSrc === errorImg ? `${classes.errorImage} ${errStyle}` : ''
        }`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          setSrc(errorImg)
          setIsloaded(true)
          // currentTarget.classList.add(errStyle)
          currentTarget.classList.add(classes.errorClass)
          currentTarget.src = errorImg
        }}
      />
    </div>
  )
}
