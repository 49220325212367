import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from './../../constants'

const progressPercent = (completed: number, base: number) =>
  Math.min((completed / base) * 100, 100)
export const styles = makeStyles(theme => ({
  container: {
    height: 50,
    width: '100%',
    backgroundColor: COLORS.whiteStain,
    border: '1px solid #EFEFEF',
    borderRadius: 50,
    position: 'relative',
  },
  child: {
    height: '100%',
    width: props => `${progressPercent(props.completed, props.base)}%`,
    backgroundColor: '#E33D40',
    borderRadius: 'inherit',
    textAlign: 'right',
  },
  completedText: {
    lineHeight: '50px',
    color: COLORS.white,
    textAlign: 'center',
  },
  baseText: {
    lineHeight: '50px',
    position: 'absolute',
    top: 0,
    right: 10,
    color: COLORS.ashGrey,
  },
  smallCompleted: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  smallCompletedText: {
    color: '#F27870',
    margin: 0,
    fontWeight: 700,
  },
  colorBg: {
    width: props => `${progressPercent(props.completed, props.base)}%`,
    height: 50,
    borderRadius: 25,
    backgroundColor: '#E33D40',
    margin: 0,
    marginRight: 10,
  },
}))
