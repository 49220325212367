import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from './../../constants'

export const styles = makeStyles(theme => ({
  disclaimer: {
    color: '#ABABAB',
    fontSize: '0.875rem',
    fontFamily: 'Graphik Light',
    lineHeight: '1.64',
  },
  tableWrapper: {
    width: '100%',
    overflowX: 'auto',
  },
  tableHeader: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '2rem',
    lineHeight: 1.3,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '1.25rem',
    },
  },
  tableRow: {
    fontSize: '1rem',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '0.857rem',
    },
  },
  table: {
    marginTop: 20,
    marginBottom: 20,
  },
  customSelect: {
    position: 'relative',
    boxSizing: 'border-box',
    marginRight: 10,
    backgroundColor: COLORS.whiteStain,
    border: `2px solid ${COLORS.whiteStain}`,
    maxWidth: 144,
    height: 54,
    lineHeight: '54px',
    width: '100%',
    '& select': {
      fontFamily: FONTS.Graphik.GraphikLight,
      fontSize: '0.75rem',
      width: '100%',
      padding: theme.spacing(1, 3, 1, 2),
      border: 'none',
      color: COLORS.black,
      backgroundColor: 'transparent',
      appearance: 'none',
      '&:active, &:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      right: '8px',
      width: 0,
      height: 0,
      marginTop: '-2px',
      borderTop: '5px solid #aaa',
      borderRight: '5px solid transparent',
      borderLeft: '5px solid transparent',
    },
  },
  dateGroup: {
    display: 'flex',
    marginBottom: 10,
  },
  midWidth: {
    maxWidth: 1230,
    width: '100%',
    margin: '0 auto',
    paddingTop: 100,
  },
  purchases: {
    backgroundColor: '#E33D40',
    color: COLORS.white,
    maxWidth: 765,
    width: '100%',
    borderRadius: 25,
    lineHeight: '50px',
    textAlign: 'center',
    marginTop: 40,
  },
  cashBackPending: {
    maxWidth: 416,
    width: '100%',
    backgroundColor: COLORS.watermelonPink,
    color: COLORS.white,
    borderRadius: 25,
    lineHeight: '50px',
    textAlign: 'center',
    marginTop: 20,
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      maxWidth: 219,
    },
  },
  cashBackPaid: {
    width: 70,
    height: 50,
    borderRadius: 25,
    backgroundColor: '#F9BEBF',
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      width: 60,
    },
  },
  cashBackPaidText: {
    color: '#F9BEBF',
    paddingLeft: 10,
  },
  dateGroups: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  betweenDateGroups: {
    marginBottom: 0,
    paddingLeft: 10,
    paddingRight: 20,
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      paddingLeft: 0,
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
  fieldName: {
    marginBottom: 10,
    fontFamily: FONTS.Graphik.GraphikRegular,
  },
  fieldValue: {
    marginBottom: 10,
  },
  tableCard: {
    backgroundColor: COLORS.whiteStain,
    marginTop: 10,
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: ' 50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    padding: theme.spacing(2.5, 2.5, 5),
  },
  // picker styles
  formControl: {
    width: '100%',
    minWidth: '100px !important',
    maxWidth: 343,
    position: 'relative',
    backgroundColor: COLORS.whiteStain,
    marginRight: '20px !important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectList: {
    ['& > select']: {
      padding: 0,
      paddingLeft: 10,
      paddingRight: 0,
      backgroundColor: 'unset !important',
      fontFamily: FONTS.Graphik.GraphikRegular,
      fontSize: '0.75rem',
      lineHeight: 'normal',
      ['& > option']: {
        fontFamily: FONTS.Graphik.GraphikRegular,
      },
    },
  },
  sortByText: {
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    display: 'flex !important',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '0.75rem',
    fontWeight: 400,
    position: 'absolute',
    bottom: 7.5,
    left: 0,
    margin: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  pickerWrapper: {
    marginRight: 10,
  },
  arrow: {
    position: 'absolute',
    right: 20,
    top: '-5px !important',
  },
  withBg: {
    backgroundColor: COLORS.whiteStain,
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  circleImgWrapper: {
    position: 'relative',
    marginRight: 10,
  },
  circleImg: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    display: 'block',
    height: 100,
    width: 80,
    objectFit: 'contain',
  },
  productTitleWrapper: {
    overflow: 'hidden',
  },
  productTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: 12,
    letterSpacing: '1.2px',
    lineHeight: '14px',
    margin: 0,
    marginBottom: 4,
  },
  brandName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 300,
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: 12,
    letterSpacing: '1.2px',
    lineHeight: '14px',
    margin: 0,
  },
  errorMsg: {
    color: COLORS.dangerRed,
    fontSize: 14,
    margin: 0,
  },
}))
