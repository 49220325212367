import { TextField, Box } from '@material-ui/core'
import { useLocation } from '@reach/router'
import classnames from 'classnames'
import { TFunction } from 'i18next'
import React, { useEffect, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import {
  checkIfSubmittable,
  getErrorMsg,
  paymentValidation,
} from '../../../utils/validation'
import { useAppState } from '../../appState'
import paypalIcon from '../../assets/images/paypal-light.svg'
import venmoIcon from '../../assets/images/venmo_logo.svg'
import axios from '../../axios'
import Button from '../../components/button'
import { styles } from '../../components/dashboard/styles'
import { ENDPOINTS } from './../../constants'
import PaymentRadioInput from '../paymentRadioInput'
import MaskedInput from 'react-text-mask'

function TextMask(props: any) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  )
}

interface SettingsTabProps {
  t: TFunction
  updateName: Function
  updateUsername: Function
}

const PaymentInfoTab = ({
  t,
  updateName,
  updateUsername,
  ...rest
}: SettingsTabProps) => {
  const [appState] = useAppState()
  const { hash } = useLocation()
  const paypalSettingsRef = useRef(null)
  const classes = styles()

  const [isChanged, setIsChanged] = useState(false)

  const [payment, setPayment] = useState({
    // isEditVisible: true,
    // isPaymentDisabled: true,
    paypalEmail: '',
    venmoAccount: '',
    defaultPayment: 'PAYPAL',
    emailErrorMsg: '',
    venmoErrorMsg: '',
    defaultPaymentErrorMsg: false,
    isLoading: false,
    showSaved: false,
  })

  useEffect(() => {
    if (hash === '#paypalSettings') paypalSettingsRef.current.scrollIntoView()
  })

  useEffect(() => {
    try {
      ;(async () => {
        const {
          data: { data },
        } = await axios.get(ENDPOINTS.user.replace(':id', appState.userId))

        if (!!data.paypalAccount || !!data.venmoAccount) {
          setPayment({
            ...payment,
            paypalEmail: data.paypalAccount || null,
            venmoAccount: data.venmoAccount || null,
            defaultPayment: data.defaultPayment || 'PAYPAL',
            // isPaymentDisabled: true,
            // isEditVisible: true,
          })
        } else {
          setPayment({
            ...payment,
            // isPaymentDisabled: false,
            // isEditVisible: false,
          })
        }
      })()
    } catch (error) {
      // console.log(error)
      throw new Error()
    }
  }, [])

  async function handlePaypalSave() {
    setPayment({
      ...payment,
      isLoading: true,
      emailErrorMsg: '',
      venmoErrorMsg: '',
      defaultPaymentErrorMsg: '',
    })

    if (payment.paypalEmail || payment.venmoAccount) {
      try {
        const isFormValid = await paymentValidation({
          email: payment.paypalEmail === null ? '' : payment.paypalEmail,
          venmoAccount:
            payment.venmoAccount === null ? '' : payment.venmoAccount,
        })

        if (isFormValid) {
          const isValidDefaultOption =
            (payment.paypalEmail && payment.defaultPayment === 'PAYPAL') ||
            (payment.venmoAccount && payment.defaultPayment === 'VENMO')
          if (isValidDefaultOption) {
            const payoutData = {
              paypalAccount: payment.paypalEmail || null,
              venmoAccount: payment.venmoAccount || null,
              defaultPayment: payment.defaultPayment,
            }

            await axios.patch(
              ENDPOINTS.user.replace(':id', appState.userId),
              payoutData
            )

            setPayment({
              ...payment,
              isLoading: false,
              showSaved: true,
              // isEditVisible: true,
              // isPaymentDisabled: true,
            })
            setIsChanged(false)
          } else {
            if (payment.defaultPayment === 'VENMO' && !payment.venmoAccount) {
              setPayment({
                ...payment,
                isLoading: false,
                venmoErrorMsg:
                  'Please enter a valid venmo account phone number.',
              })
            } else if (
              payment.defaultPayment === 'PAYPAL' &&
              !payment.paypalEmail
            ) {
              setPayment({
                ...payment,
                isLoading: false,
                emailErrorMsg: 'invalidPaypalEmail',
              })
            } else {
              setPayment({
                ...payment,
                isLoading: false,
                defaultPaymentErrorMsg:
                  'default payment method must be valid option',
              })
            }
          }
        }
      } catch (error) {
        if (
          error?.response?.data?.message ===
          '"paypalAccount" must be a valid email'
        ) {
          setPayment({
            ...payment,
            isLoading: false,
            emailErrorMsg: 'invalidEmail',
          })
        } else {
          setPayment({
            ...payment,
            emailErrorMsg: getErrorMsg(error, 'email'),
            venmoErrorMsg: getErrorMsg(error, 'venmoAccount'),
          })
        }
      }
    } else {
      if (payment.defaultPayment === 'VENMO') {
        setPayment({
          ...payment,
          defaultPaymentErrorMsg: 'please enter venmo account phone number',
        })
      } else if (payment.defaultPayment === 'PAYPAL') {
        setPayment({
          ...payment,
          defaultPaymentErrorMsg: 'please enter paypal email address',
        })
      } else {
        setPayment({
          ...payment,
          defaultPaymentErrorMsg: 'please enter an payment method',
        })
      }
    }
  }

  // const handleOnEdit = () => {
  //   setPayment({
  //     ...payment,
  //     // isEditVisible: false,
  //     // isPaymentDisabled: false,
  //   })
  // }

  const handleDefaultPayment = (e: any) => {
    const value = e.currentTarget.value
    setPayment({
      ...payment,
      defaultPaymentErrorMsg: false,
      defaultPayment: value,
      showSaved: false,
    })
    setIsChanged(true)
  }

  const subtitleClasses = classnames({
    [classes.titlesSpacing2]: true,
    [classes.subtitles2]: true,
  })

  return (
    <div className={classes.paymentInfo}>
      <p
        className={subtitleClasses}
        id="paypalSettings"
        ref={paypalSettingsRef}
      >
        {t('dashboard.paymentInfo')}
      </p>

      <section className={classes.form}>
        <div className={classes.paypalBox}>
          <img
            src={paypalIcon}
            alt={t('dashboard.paypal')}
            className={classes.paypalImg}
          />
          {/* {payment.isEditVisible && (
            <button className={classes.editPaypalButton} onClick={handleOnEdit}>
              <Tooltip title={t('shared.edit')} arrow>
                <img src={editIcon} alt={t('shared.edit')} />
              </Tooltip>
            </button>
          )} */}
        </div>
        <div className={classes.inputWrapper}>
          <p className={classes.paymentErrorMsg}>
            {payment.emailErrorMsg && t(`dashboard.${payment.emailErrorMsg}`)}
          </p>
          <TextField
            fullWidth
            label={t('shared.paypalEmailAddress')}
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldColor,
              classes: {
                disabled: classes.disabledTextField,
                input: classes.textFieldInput,
              },
            }}
            InputLabelProps={{
              classes: {
                formControl: classes.textFieldLabelFormControl,
                shrink: classes.textFieldLabelShrink,
                root: classes.textFieldLabelRoot,
              },
            }}
            variant="filled"
            value={payment.paypalEmail}
            className={classes.paymentInputSpacing}
            onChange={event => {
              setIsChanged(true)
              setPayment({
                ...payment,
                showSaved: false,
                emailErrorMsg: '',
                defaultPaymentErrorMsg: '',
                paypalEmail: event.target.value,
              })
            }}
            onKeyPress={event =>
              !payment.isLoading && checkIfSubmittable(event, handlePaypalSave)
            }
          />
          <PaymentRadioInput
            onChange={handleDefaultPayment}
            checked={payment.defaultPayment === 'PAYPAL'}
            id="PAYPAL"
            label="make PayPal my default payment method"
            disabled={false}
          />
        </div>
        <div className={classes.paypalBox}>
          <img src={venmoIcon} alt="venmo" className={classes.venmoImg} />
        </div>
        <div
          className={classes.inputWrapper + ' ' + classes.venmoInputPlaceholder}
        >
          <p className={classes.paymentErrorMsg}>{payment.venmoErrorMsg}</p>

          <TextField
            fullWidth
            label={t('shared.venmoAccountNumber')}
            InputProps={{
              inputComponent: TextMask,
              disableUnderline: true,
              className: classes.textFieldColor,
              classes: {
                disabled: classes.disabledTextField,
                input: classes.textFieldInput,
              },
            }}
            InputLabelProps={{
              classes: {
                formControl: classes.textFieldLabelFormControl,
                shrink: classes.textFieldLabelShrink,
                root: classes.textFieldLabelRoot,
              },
            }}
            variant="filled"
            value={payment.venmoAccount}
            className={classes.paymentInputSpacing}
            onChange={event => {
              setIsChanged(true)
              setPayment({
                ...payment,
                showSaved: false,
                venmoErrorMsg: '',
                defaultPaymentErrorMsg: '',
                venmoAccount: event.target.value,
              })
            }}
            onKeyPress={event =>
              !payment.isLoading && checkIfSubmittable(event, handlePaypalSave)
            }
          />

          <PaymentRadioInput
            onChange={handleDefaultPayment}
            checked={payment.defaultPayment === 'VENMO'}
            id="VENMO"
            label="make Venmo my default payment method"
            disabled={false}
          />
        </div>
        <p className={classes.paragraph}>
          {t('dashboard.paymentInfoParagraph', {
            threshold: appState.settings?.paymentThreshold,
          })}
        </p>
        <Box mt={4} style={{ position: 'relative' }}>
          <p className={classes.errorMsg}>{payment.defaultPaymentErrorMsg}</p>
        </Box>
        <Button
          label={
            payment.showSaved
              ? t('dashboard.saved')
              : t('dashboard.saveChanges')
          }
          onClick={handlePaypalSave}
          isSubmitting={payment.isLoading}
          disabled={payment.isLoading || !isChanged}
        />
      </section>
    </div>
  )
}

export default withTranslation()(PaymentInfoTab)
