import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  container: {
    height: 34,
    width: 221,
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    height: 14,
    width: 55,
    cursor: 'pointer',
  },
}))
