import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  trendingHeading: {
    marginBottom: 10,
  },
  trendingSubtitle: {
    marginBottom: 25,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      // marginBottom: 10,
    },
  },
  heart: {
    position: 'absolute',
    marginLeft: '14px',
  },
  trendingContainer: {
    width: '100%',
    paddingTop: 100,
    overflow: 'hidden',
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      paddingTop: 35,
    },

    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '90%',
      margin: '0 auto',
      paddingTop: 30,
      ['& .MuiGrid-spacing-xs-5']: {
        margin: 0,
        width: '100%',
        paddingLeft: 20,
      },
      ['& .MuiGrid-spacing-xs-5 .MuiGrid-item']: {
        padding: 0,
      },
    },
  },
  gridContainer: {
    ['& .MuiGrid-spacing-xs-5']: {
      margin: 0,
      width: '100%',
      paddingLeft: 20,
    },
    ['& .MuiGrid-spacing-xs-5 .MuiGrid-item']: {
      padding: 0,
    },
  },
  trendingContainer2: {
    width: '100%',
    // paddingTop: 100,
    overflow: 'hidden',
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      // width: '90%',
      margin: '0 auto',
      // paddingTop: 80,
    },
  },
  trendingContainer3: {
    width: '100%',
    // paddingTop: 100,
    overflow: 'hidden',
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      // paddingTop: 80,
    },
  },
  mobileEdgeSpace: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      fontSize: '2.5rem',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '3.75rem',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '2.25rem',
      marginBottom: 20,
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      // paddingLeft: 20,
      // paddingRight: 20,
    },
  },
  CHRbArrowButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
    },
  },
  topProductsContainer: {
    display: 'flex',
    ['& > div:first-child']: {
      marginRight: 13,
      ['& > button:last-child img']: {
        marginLeft: '3px !important',
        marginRight: '6px !important',
      },
    },
    ['& > div:last-child']: {
      marginLeft: 14,
      ['& > button:first-child img']: {
        marginLeft: '10px !important',
        marginRight: '5px !important',
      },
    },
  },
  arrowMaxHeight: {
    minWidth: 70,
    minHeight: 269.59,
    maxHeight: 269.59,
    display: 'flex',
    alignItems: 'center',
    background: '#FAFBFD',
  },
  tabletMode: {
    ['& > .MuiGrid-container']: {
      overflow: 'auto',
      scrollbarWidth: 'none',
      MsOverflowStyle: 'none',
    },
    ['& > .MuiGrid-container::-webkit-scrollbar']: {
      display: 'none',
    },
  },
}))
