import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  textContent: {
    maxWidth: 706,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
  inputContainer: {
    width: '100%',
    maxWidth: 706,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loading: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    background: 'rgba(255,255,255,0.5)',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
  pasteLinkInput: {
    ['& > input']: {
      color: theme.palette.common.black,
      letterSpacing: 1.2,
      textAlign: 'left',
    },
  },
  convertedLink: {
    position: 'relative',
    ['& > input']: {
      color: theme.palette.common.black,
      letterSpacing: 1.2,
      textAlign: 'center',
      background: 'white',
      border: `1px solid ${COLORS.lightGrey}`,
    },
    ['& > button']: {
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100px',
      margin: 0,
    },
  },
  socialButton: {
    ['& > button']: {
      width: '100%',
      maxWidth: 706,
      border: `2px solid ${theme.palette.common.black} !important`,
      height: 54,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 20px !important',
    },
  },
  socialIcon: {
    display: 'block',
    width: '100%',
    maxWidth: 24,
  },
  socialText: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    display: 'block',
    margin: '0px auto',
    fontSize: '0.75rem',
    color: theme.palette.common.black,
  },
  subtitle: {
    marginBottom: 0,
    paddingBottom: 30,
    fontWeight: 300,
  },
  alertText: {
    fontSize: '0.875rem' /*equals 14px*/,
  },
  infoMsg: {
    color: COLORS.infoBlue,
  },
  errorMsg: {
    color: '#2E7C86',
    fontWeight: 700,
    marginBottom: 0,
  },
  convertAnotherLinkBtn: {
    fontSize: '1rem' /*equals 16px*/,
    fontFamily: FONTS.Graphik.GraphikLight,
    background: 'transparent',
    border: 'none',
    display: 'block',
    margin: '0px auto',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  shopLinkBtn: {
    color: theme.palette.common.white,
    fontSize: '1rem' /*equals 16px*/,
    fontFamily: FONTS.Graphik.GraphikLight,
    background: theme.palette.common.black,
    margin: '0px auto',
    textDecoration: 'none',
    cursor: 'pointer',
    width: '100%',
    maxWidth: 706,
    border: `2px solid ${theme.palette.common.black} !important`,
    height: 54,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    margin: '0px auto',
  },
  smsButton: {
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    cursor: 'pointer',
    color: COLORS.darkGrey2,
  },
  createLinkBtns: {
    width: '100%',
    maxWidth: 706,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  boardBtn: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    height: 54,
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    cursor: 'pointer',
    borderColor: theme.palette.common.black,
  },
}))
