import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  card: {
    width: 205,
    height: 222,
    position: 'relative',
    color: theme.palette.common.black,
    cursor: 'pointer',
    backgroundColor: COLORS.whiteStain,
    ['&:hover']: {
      '& p': {
        color: theme.palette.common.black,
      },
    },
  },
  whiteCard: {
    backgroundColor: COLORS.white,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      backgroundColor: COLORS.whiteStain,
      width: '100%',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      backgroundColor: COLORS.whiteStain,
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      backgroundColor: COLORS.whiteStain,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      backgroundColor: COLORS.whiteStain,
    },
  },
  cashBackText: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    color: COLORS.lightGrey,
    fontSize: '0.8rem',
    letterSpacing: '0.5px',
    margin: 0,
    position: 'absolute',
    bottom: '3%',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    transition: '0.5s',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      color: COLORS.black,
    },
  },
  cashBackTextActive: {},
  logo: {
    position: 'absolute',
    top: '10%',
    right: 0,
    bottom: 0,
    left: '15%',
    width: '70%',
    height: '70%',
    objectFit: 'contain',
  },
}))
