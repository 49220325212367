import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(theme => ({
  topSection: {
    width: '90%',
    paddingTop: 40,
    margin: '0 auto',
    overflow: 'visible',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      minHeight: '582px',
    },
    [`@media (min-width: ${BREAKPOINTS.lg + 1}px)`]: {
      minHeight: '435px',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      paddingTop: 20,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '100%',
      ['& > .MuiGrid-spacing-xs-3']: {
        margin: 0,
        width: '100%',
      },
      ['& > .MuiGrid-spacing-xs-3 > .MuiGrid-item']: {
        padding: 0,
        // paddingLeft: 8,
        // paddingRight: 8,
      },
    },
  },
  joinLinkSpan: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: '10px',
  },
  blogItemContainer: {
    width: '100%',
    // minWidth: 464,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: '100%',
    },
  },
  topSectionLinks: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      marginTop: '20px',
    },
  },
  accordionLink: {
    color: COLORS.black,
  },
  circle: {
    width: 252,
    height: 252,
    backgroundColor: '#175972',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'none',
    },
  },
  circleText: {
    fontSize: '1.875rem !important',
    color: theme.palette.common.white,
    marginBottom: '5px !important',
  },
  circleImg: {
    width: '252px !important',
    height: '252px !important',
    cursor: 'pointer !important',
  },
  circleInfo: {
    padding: 30,
    textAlign: 'center',
  },
  rowLink: {
    color: theme.palette.common.black,
    opacity: '0.8',
    textDecoration: 'none',
    cursor: 'pointer',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      width: '50%',
      marginTop: '20px',
    },
  },
  rowLinkText: {
    display: 'inline-block',
    fontFamily: 'Schnyder XL Light',
    lineHeight: '86px',
    letterSpacing: '-0.01562em',
    marginBottom: 0,
    borderBottom: '1px solid',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      fontSize: '2.5rem',
    },
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      fontSize: '3rem',
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      lineHeight: '40px',
    },
  },
  blogLinks: {
    flexWrap: 'wrap',
    [`@media (min-width: ${BREAKPOINTS.lg + 1}px)`]: {
      paddingTop: 60,
    },
  },
  hideCircle: {
    display: 'none',
  },
  customLinks: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      ['& > div:not(:last-child)']: { marginBottom: '20px' },
    },
  },
  addExt: {
    display: 'inline',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  pointer: {
    cursor: 'pointer',
  },
  mobileEdgeSpace2: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  topSectionOld: {
    width: '71%',
    margin: '0 auto',
    overflow: 'visible',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      maxHeight: '582px',
    },
    [`@media (min-width: ${BREAKPOINTS.lg + 1}px)`]: {
      maxHeight: '582px',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
    },
  },
}))
