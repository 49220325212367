import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import axios from '../../axios'
import { TFunction } from 'i18next'
import TabPanel from '../tabs'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { styles } from './styles'
import SettingsTab from '../../components/settingsTab'
import PaymentInfoTab from '../../components/paymentInfoTab'
import ContentContainer from './../contentContainer'
import { ENDPOINTS, FONTS, COLORS, ROUTES } from './../../constants'
import { Link } from 'gatsby'
import * as moment from 'moment'
import SEO from '../seo'
import CHRSpinner from '../spinner'
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  withStyles,
  Grid,
} from '@material-ui/core'
import Layout from '../../layouts/defaultLayout'
// import CHRUploadAvatar from './../AmplifyUpload'
import CHROutlinedButton from './../outlinedButton'
import AddExtensionPopup from '../popups/automaticPopOver/addExtensionPopUp'
import { useAppState } from '../../appState'
import ProTab from '../../components/proTab'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Doughnut } from 'react-chartjs-2'
import birdAvatar from '../../assets/images/bird-avatar.png'
import profileActiveIcon from '../../assets/images/profile-active.svg'
// import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded'

interface FinancialDataAttributes {
  pending: string
  lastClosedOut: string
  receivableMilestone: string
  isLoading: boolean
  error: boolean
}

interface DashboardPageProps {
  t: TFunction
  financialData: FinancialDataAttributes
  fullName: string
  userId: number
}

const tabsNames = {
  dashboardChirpyestPro: 'chirpyest-pro',
  cashback: 'cashback',
  paymentInfo: 'paymentInfo',
  settings: 'settings',
  paypalSettings: 'paypalSettings',
}

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: COLORS.whiteStain,
    },
    '& td': {
      maxWidth: 250,
    },
  },
}))(TableRow)

const DashboardPage = ({
  t,
  financialData: earnings,
  fullName: userName,
  userId: userId,
  ...rest
}: DashboardPageProps) => {
  const classes = styles()
  const [isThereCashBack, setIsThereCashBack] = useState(false)
  const [appstate] = useAppState()
  const [tabValue, setTabValue] = useState(appstate.userProfile.isPro ? 1 : 0)
  const { hash } = useLocation()
  const [fullName, setFullName] = useState('')
  const [isLoading, setIsLoading] = useState({
    userRecruits: true,
  })
  const [username, setUsername] = useState(appstate.username)
  const [isAddExtensionPopUpOpen, setIsAddExtensionPopUpOpen] = useState(false)
  const [isLoadingBreakDown, setIsLoadingBreakDown] = useState(true)
  const [breakDownError, setBreakDownError] = useState('')
  // const [isLoadingOver, setIsLoadingOver] = useState(true)
  // const [overPaymentError, setOverPaymentError] = useState('')
  const [userRecruitsError, setUserRecruitsError] = useState('')
  const [chartError, setChartError] = useState('')
  // const [overPayments, setOverPayments] = useState({
  //   data: [],
  //   finishedLoading: false,
  // })
  const [payments, setPayments] = useState({
    data: [],
    finishedLoading: false,
    offset: 0,
    size: 10,
  })
  const [userRecruits, setUserRecruits] = useState({
    data: [],
    finishedLoading: false,
    offset: 0,
    size: 6,
  })

  const [paypalEmail, setPaypalEmail] = useState('')

  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
  const [chartResult, setChartResult] = useState(null)

  useEffect(() => {
    const tab = hash.split('#')[1]
    if (tab === tabsNames.paypalSettings || tab === tabsNames.settings)
      setTabValue(appstate.userProfile.isPro ? 3 : 2)
    else if (tab === tabsNames.paymentInfo)
      setTabValue(appstate.userProfile.isPro ? 2 : 1)
    else if (tab === tabsNames.cashback)
      setTabValue(appstate.userProfile.isPro ? 1 : 0)
    else setTabValue(0)

    if (
      Number(
        earnings?.pending ||
          earnings?.lastClosedOut ||
          earnings?.receivableMilestone
      ) > 0
    ) {
      setIsThereCashBack(true)
    }

    let pageIndex = Math.floor(Math.random() * 4)
    if (
      !window.localStorage.getItem('extensionDownloaded') &&
      pageIndex === 3
    ) {
      setIsAddExtensionPopUpOpen(true)
    }
  }, [earnings?.isLoading, hash])

  async function getBreakDown() {
    try {
      setIsLoadingBreakDown(true)
      setBreakDownError('')

      const url = ENDPOINTS.userPayments.replace(':id', `${userId}`)
      const response = await axios.get(`${url}`, {
        params: {
          offset: payments.offset,
          size: payments.size,
        },
      })
      const data = response.data.data
      // 10 is hard coded right now, it can be changed from backend and here to something else
      setPayments({
        finishedLoading: data.length === 0 || data.length < payments.size,
        offset: payments.offset + payments.size,
        size: 10,
        data: payments.data.concat(data),
      })
      setIsLoadingBreakDown(false)
    } catch (err) {
      setIsLoadingBreakDown(false)
      setBreakDownError(t('messages.somethingWentWrong'))
    }
  }

  useEffect(() => {
    async function getData() {
      await getBreakDown()
    }
    async function getPaypalEmail() {
      try {
        const {
          data: { data },
        } = await axios.get(ENDPOINTS.user.replace(':id', appstate.userId))

        if (!!data.paypalAccount) {
          setPaypalEmail(data.paypalAccount)
        }
      } catch (err) {
        setPaypalEmail('')
      }
    }

    getData()
    getPaypalEmail()
    getChartData()
    getUserRecruits()
  }, [])

  const getUserRecruits = async () => {
    try {
      const response = await axios.get(ENDPOINTS.getUserRecruits)
      const data = response.data.data
      let dataToShow = data.slice(userRecruits.offset, userRecruits.size)

      setUserRecruits({
        finishedLoading: data.length === 0 || data.length < userRecruits.size,
        offset: userRecruits.size,
        size: 6 + userRecruits.size,
        data: userRecruits.data.concat(dataToShow),
      })
      setIsLoading(prevState => ({ ...prevState, userRecruits: false }))
    } catch (err) {
      setIsLoading(prevState => ({ ...prevState, userRecruits: false }))
      setUserRecruitsError(t('messages.somethingWentWrong'))
    }
  }

  const currentYear = moment().year()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
    let location = ''
    if (newValue === 0)
      location = appstate.userProfile.isPro
        ? tabsNames.dashboardChirpyestPro
        : tabsNames.cashback
    else if (newValue === 1)
      location = appstate.userProfile.isPro
        ? tabsNames.cashback
        : tabsNames.paymentInfo
    else if (newValue === 2)
      location = appstate.userProfile.isPro
        ? tabsNames.paymentInfo
        : tabsNames.settings
    else if (newValue === 3) location = tabsNames.settings
    navigate(`#${location}`, { replace: true })
  }

  const updateFullName = (newName: string) => {
    setFullName(newName)
  }

  const updateUsername = (newUserName: string) => {
    setUsername(newUserName)
  }

  const truncateUserName = (name: string = '') => {
    if (name.length > 23) {
      return name.slice(0, 23) + '...'
    }
    return name
  }

  const closeAddExtensionPopUp = () => {
    setIsAddExtensionPopUpOpen(false)
  }

  const getChartData = async () => {
    try {
      const res = await axios.get(ENDPOINTS.salesChannel)

      const {
        shared_products_sales,
        shared_products_items,
        shopping_board_sales,
        shopping_board_items,
        browser_extension_sales,
        browser_extension_items,
        other_sales,
        other_items,
      }: any = Object.entries(res.data.data).reduce((acc: any, [k, v]) => {
        acc[k] = Number(v)
        return acc
      }, {})

      const total =
        shared_products_sales +
        shopping_board_sales +
        browser_extension_sales +
        other_sales

      const result = {
        sharedProduct: {
          percentage: ((shared_products_sales / total) * 100).toFixed(2),
          money: shared_products_sales.toFixed(2),
          items: shared_products_items,
        },
        board: {
          percentage: ((shopping_board_sales / total) * 100).toFixed(2),
          money: shopping_board_sales.toFixed(2),
          items: shopping_board_items,
        },
        extension: {
          percentage: ((browser_extension_sales / total) * 100).toFixed(2),
          money: browser_extension_sales.toFixed(2),
          items: browser_extension_items,
        },
        other: {
          percentage: ((other_sales / total) * 100).toFixed(2),
          money: other_sales.toFixed(2),
          items: other_items,
        },
      }
      setChartResult(result)
    } catch (err) {
      setChartError(t('messages.somethingWentWrong'))
    }
  }

  // useEffect(() => {
  //   const getOverPayments = async () => {
  //     try {
  //       setIsLoadingOver(true)
  //       setOverPaymentError('')
  //       const response = await axios.get(
  //         ENDPOINTS.overPayments.replace(':id', `${userId}`)
  //       )
  //       const data = response.data.data
  //       // 10 is hard coded right now, it can be changed from backend and here to something else
  //       setOverPayments({
  //         data: data,
  //         finishedLoading: data.length === 0 || data.length < payments.size,
  //       })
  //       setIsLoadingOver(false)
  //     } catch (err) {
  //       setIsLoadingOver(false)
  //       setOverPaymentError(t('messages.somethingWentWrong'))
  //     }
  //   }
  //   getOverPayments()
  // }, [])

  return (
    <Layout>
      <SEO title={t('dashboard.metaTitle')} />
      <div className={classes.background}>
        <ContentContainer>
          <section className={classes.personalInfo}>
            <div className={classes.avatarWrapper}>
              <div className={classes.avatarCircle}>
                {appstate.userProfile.imageUrl ? (
                  <img
                    src={appstate.userProfile.imageUrl}
                    alt="avatar"
                    className={classes.userAvatar}
                  />
                ) : (
                  <img
                    src={birdAvatar}
                    alt="avatar"
                    className={classes.userAvatar}
                  />
                )}
              </div>
              {appstate.userProfile.isPro && (
                <div className={classes.profileActive}>
                  <img src={profileActiveIcon} alt={t('shared.chirpyest')} />
                </div>
              )}
            </div>
            <div className={classes.rightInfo}>
              <Typography
                variant="h1"
                component="h2"
                classes={{ h1: classes.userName }}
              >
                {username || fullName || truncateUserName(userName)}
              </Typography>
              <Typography variant="h3" className={classes.yourAccount}>
                {t('dashboard.yourAccount')}
              </Typography>
            </div>
          </section>
        </ContentContainer>
      </div>
      <section>
        {!isPhone && (
          <section className={classes.tabsSection}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="dashboard tabs"
              variant="fullWidth"
            >
              {appstate.userProfile.isPro && (
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.proSelected,
                  }}
                  label="chirpyest pro"
                />
              )}
              <Tab
                classes={{
                  root: classes.root,
                  selected: classes.selected,
                }}
                label={t('dashboard.cashBack')}
              />
              <Tab
                classes={{
                  root: classes.root,
                  selected: classes.selected,
                }}
                label={t('dashboard.paymentInfo')}
              />
              <Tab
                classes={{
                  root: classes.root,
                  selected: classes.selected,
                }}
                label={t('dashboard.accountAndSetting')}
              />
            </Tabs>
          </section>
        )}

        {isPhone && (
          <section className={classes.tabsSection2}>
            {appstate.userProfile.isPro && (
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => {
                  appstate.userProfile.isPro
                    ? setTabValue(newValue)
                    : setTabValue(newValue)
                }}
                aria-label="dashboard tabs"
                variant="fullWidth"
              >
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.proSelected,
                  }}
                  label="chirpyest pro"
                />
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label={t('dashboard.cashBack')}
                />
              </Tabs>
            )}
            {!appstate.userProfile.isPro && (
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="dashboard tabs"
                variant="fullWidth"
              >
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label={t('dashboard.cashBack')}
                />
              </Tabs>
            )}
            <Tabs
              value={appstate.userProfile.isPro ? tabValue - 2 : tabValue - 1}
              onChange={(e, newValue) => {
                appstate.userProfile.isPro
                  ? setTabValue(newValue + 2)
                  : setTabValue(newValue + 1)
              }}
              aria-label="dashboard tabs"
              variant="fullWidth"
            >
              <Tab
                classes={{
                  root: classes.root,
                  selected: classes.selected,
                }}
                label={t('dashboard.paymentInfo')}
              />
              <Tab
                classes={{
                  root: classes.root,
                  selected: classes.selected,
                }}
                label={t('dashboard.accountAndSetting')}
              />
            </Tabs>
          </section>
        )}

        {appstate.userProfile.isPro && (
          <ContentContainer>
            <TabPanel value={tabValue} index={0}>
              <ProTab />
            </TabPanel>
          </ContentContainer>
        )}
        <ContentContainer>
          <div className={classes.content}>
            <TabPanel
              value={tabValue}
              index={appstate.userProfile.isPro ? 1 : 0}
            >
              {earnings?.error ? (
                <div className={classes.cashBackSection}>
                  <Typography variant="h1" component="h6">
                    {t('messages.somethingWentWrong')}
                  </Typography>
                </div>
              ) : (
                <>
                  {earnings?.isLoading ? (
                    <CHRSpinner />
                  ) : (
                    <>
                      {isThereCashBack ? (
                        <div className={classes.cashBackSection}>
                          <p className={classes.subTitle}>
                            {t('dashboard.niceWork')}
                            <br />$
                            {earnings &&
                              Number(earnings.pending).toFixed(2)}{' '}
                            {t('dashboard.pendingAnd')}
                            <br />$
                            {earnings &&
                              Number(earnings.lastClosedOut).toFixed(2)}{' '}
                            {t('dashboard.paidLastMonth')}
                          </p>

                          {/* {Number(
                            appstate.userProfile.userDoublePayment
                              .remainingAmount
                          ) > 1 && <>
                          
                          </>} */}
                          {/* <br /> */}

                          {Number(
                            appstate.userProfile.userDoublePayment
                              .remainingAmount
                          ) > 1 && (
                            <>
                              <span className={classes.overPaymentTwo}>
                                {'(applied to your overpayment balance below)'}
                              </span>
                              <p className={classes.subTitleBetween}>
                                {'($'}
                                {appstate.userProfile.userDoublePayment &&
                                  Number(
                                    appstate.userProfile.userDoublePayment
                                      .amount
                                  ).toFixed(2)}
                                {') overpaid on 10/15/22*'}
                                <br />
                              </p>
                              <span className={classes.overPayment}>
                                {'($'}
                                {appstate.userProfile.userDoublePayment &&
                                  Number(
                                    appstate.userProfile.userDoublePayment
                                      .remainingAmount
                                  ).toFixed(2)}
                                {') remaining overpayment balance'}
                              </span>
                            </>
                          )}
                          <br />
                          <p className={classes.subTitleTwo}>
                            $
                            {earnings &&
                              Number(earnings.receivableMilestone).toFixed(
                                2
                              )}{' '}
                            {t('dashboard.totalPaid')}
                            {currentYear}
                          </p>
                          <Typography variant="subtitle1" component="p">
                            {Number(
                              appstate.userProfile.userDoublePayment
                                .remainingAmount
                            ) > 1 && (
                              <>
                                {t('dashboard.overPaymentNote')}
                                <br />
                              </>
                            )}
                            {t('dashboard.pending')}
                            <br />
                            {t('dashboard.addDisclaimer')}
                            see{' '}
                            <Link
                              to={ROUTES.terms}
                              className={`${classes.underLinedText} ${classes.clickable}`}
                            >
                              our terms here
                            </Link>
                            <br />
                            {t('dashboard.cashbackDisclaimer', {
                              threshold: appstate.settings.paymentThreshold,
                            })}
                            <br />
                            {t('dashboard.addDisclaimerNote')}
                          </Typography>
                          <div className={classes.paypalBtn}>
                            <CHROutlinedButton
                              label={
                                paypalEmail
                                  ? 'change your paypal/venmo information'
                                  : 'enter your paypal/venmo information to get paid'
                              }
                              font={FONTS.Graphik.GraphikMedium}
                              onClick={() => {
                                const settingsTab = appstate.userProfile.isPro
                                  ? 3
                                  : 2
                                setTabValue(settingsTab)
                                navigate(ROUTES.paymentInfo)
                              }}
                            />
                          </div>
                          <div className={classes.cashBackSection}>
                            <p className={classes.subtitles}>
                              payment breakdown
                            </p>
                            {isLoadingBreakDown ? (
                              <CHRSpinner />
                            ) : (
                              <>
                                {!isPhone ? (
                                  <div className={classes.tableWrapper}>
                                    <Table className={classes.table}>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell
                                            className={classes.tableHeader}
                                          >
                                            date
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableHeader}
                                          >
                                            retailer
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableHeader}
                                          >
                                            purchase price
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableHeader}
                                          >
                                            cash back pending
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableHeader}
                                          >
                                            cash back paid
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {payments.data.map((row: any) => (
                                          <StyledTableRow key={`${row.id}`}>
                                            <TableCell>
                                              {moment(row.create_date).format(
                                                'MM/DD/YY'
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {row.brand_name}
                                            </TableCell>
                                            <TableCell>
                                              {row.commission === 0
                                                ? 'cash back not available for this item'
                                                : `$${row.amount.toFixed(2)}`}
                                            </TableCell>
                                            <TableCell>
                                              {row.commission === 0
                                                ? `$${row.commission.toFixed(
                                                    2
                                                  )}`
                                                : row.ispaid
                                                ? '-'
                                                : `$${row.commission.toFixed(
                                                    2
                                                  )}`}
                                            </TableCell>
                                            <TableCell>
                                              {row.commission === 0
                                                ? `$${row.commission.toFixed(
                                                    2
                                                  )}`
                                                : row.ispaid
                                                ? `$${row.commission.toFixed(
                                                    2
                                                  )}`
                                                : '-'}
                                            </TableCell>
                                          </StyledTableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </div>
                                ) : (
                                  <div>
                                    {payments.data.map((row: any) => (
                                      <Grid
                                        container
                                        className={classes.tableCard}
                                      >
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldName}
                                          >
                                            date
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldValue}
                                          >
                                            {moment(row.create_date).format(
                                              'MM/DD/YY'
                                            )}
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldName}
                                          >
                                            retailer
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldValue}
                                          >
                                            {row.brand_name}
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldName}
                                          >
                                            purchase price
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldValue}
                                          >
                                            {row.commission === 0
                                              ? 'cash back not available for this item'
                                              : `$${row.amount.toFixed(2)}`}
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldName}
                                          >
                                            cash back pending
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldValue}
                                          >
                                            {row.commission === 0
                                              ? `$${row.commission.toFixed(2)}`
                                              : row.ispaid
                                              ? '-'
                                              : `$${row.commission.toFixed(2)}`}
                                            : `$${row.commission.toFixed(2)}`}
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldName}
                                          >
                                            cash back paid
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.fieldValue}
                                          >
                                            {row.commission === 0
                                              ? `$${row.commission.toFixed(2)}`
                                              : row.ispaid
                                              ? `$${row.commission.toFixed(2)}`
                                              : '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </div>
                                )}
                                {!payments.finishedLoading && (
                                  <CHROutlinedButton
                                    label={t('dashboard.loadMore')}
                                    font={FONTS.Graphik.GraphikMedium}
                                    onClick={getBreakDown}
                                  />
                                )}
                                {breakDownError && (
                                  <p className={classes.customErrorMsg}>
                                    {breakDownError}
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                          {/* Over Payment  */}
                          {/* {Number(
                            appstate.userProfile.userDoublePayment
                              .remainingAmount
                          ) > 1 && (
                            <div className={classes.cashBackSection}>
                              <p className={classes.subtitles}>
                                over payment breakdown
                              </p>
                              {isLoadingOver ? (
                                <CHRSpinner />
                              ) : (
                                <>
                                  {!isPhone ? (
                                    <div className={classes.tableWrapper}>
                                      <Table className={classes.table}>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              className={classes.tableHeader}
                                            >
                                              date
                                            </TableCell>
                                            <TableCell
                                              className={classes.tableHeader}
                                            >
                                              actual payment
                                            </TableCell>
                                            <TableCell
                                              className={classes.tableHeader}
                                            >
                                              amount deducted
                                            </TableCell>
                                            <TableCell
                                              className={classes.tableHeader}
                                            >
                                              amount received
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {overPayments.data.map((row: any) => (
                                            <StyledTableRow key={`${row.id}`}>
                                              <TableCell>
                                                {moment(row?.created_at).format(
                                                  'MM/DD/YY'
                                                )}
                                              </TableCell>
                                              <TableCell>{`$${Number(
                                                row?.paypal_amount
                                              ).toFixed(2)}`}</TableCell>
                                              <TableCell>{`$${Number(
                                                row?.deduct_amount
                                              ).toFixed(2)}`}</TableCell>
                                              <TableCell>{`$${Number(
                                                row?.received_amount
                                              ).toFixed(2)}`}</TableCell>
                                            </StyledTableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </div>
                                  ) : (
                                    <div>
                                      {overPayments.data.map((row: any) => (
                                        <Grid
                                          container
                                          className={classes.tableCard}
                                        >
                                          <Grid item sm={6} xs={6}>
                                            <Typography
                                              variant="subtitle1"
                                              className={classes.fieldName}
                                            >
                                              date
                                            </Typography>
                                          </Grid>
                                          <Grid item sm={6} xs={6}>
                                            <Typography
                                              variant="subtitle1"
                                              className={classes.fieldValue}
                                            >
                                              {moment(row?.created_at).format(
                                                'MM/DD/YY'
                                              )}
                                            </Typography>
                                          </Grid>
                                          <Grid item sm={6} xs={6}>
                                            <Typography
                                              variant="subtitle1"
                                              className={classes.fieldName}
                                            >
                                              actual payment
                                            </Typography>
                                          </Grid>
                                          <Grid item sm={6} xs={6}>
                                            <Typography
                                              variant="subtitle1"
                                              className={classes.fieldValue}
                                            >
                                              {`$${Number(
                                                row?.paypal_amount
                                              ).toFixed(2)}`}
                                            </Typography>
                                          </Grid>
                                          <Grid item sm={6} xs={6}>
                                            <Typography
                                              variant="subtitle1"
                                              className={classes.fieldName}
                                            >
                                              amount deducted
                                            </Typography>
                                          </Grid>
                                          <Grid item sm={6} xs={6}>
                                            <Typography
                                              variant="subtitle1"
                                              className={classes.fieldValue}
                                            >
                                              {`$${Number(
                                                row?.deduct_amount
                                              ).toFixed(2)}`}
                                            </Typography>
                                          </Grid>
                                          <Grid item sm={6} xs={6}>
                                            <Typography
                                              variant="subtitle1"
                                              className={classes.fieldName}
                                            >
                                              amount received
                                            </Typography>
                                          </Grid>
                                          <Grid item sm={6} xs={6}>
                                            <Typography
                                              variant="subtitle1"
                                              className={classes.fieldValue}
                                            >
                                              {`$${Number(
                                                row?.received_amount
                                              ).toFixed(2)}`}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ))}
                                    </div>
                                  )}

                                  {overPaymentError && (
                                    <p className={classes.customErrorMsg}>
                                      {overPaymentError}
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                          )} */}
                        </div>
                      ) : (
                        <div className={classes.cashBackSection}>
                          <p className={classes.subTitleThree}>
                            {t('dashboard.noCashBack')}
                          </p>
                          <Typography variant="subtitle1" component="p">
                            {t('dashboard.pending')}
                          </Typography>
                        </div>
                      )}
                      {/* activate when you want to view the monthly-breakdown chart:{isThereCashBack && <MonthlyClosedOutBreakdown />} */}
                    </>
                  )}
                </>
              )}
              {/* signed up by you - your recruits */}
              <section className={classes.midWidth}>
                <Typography variant="h1">
                  signed up by you - your recruits
                </Typography>
                {appstate?.userProfile?.isReferralBonus && (
                  <p className={classes.disclaimer}>
                    * you will receive sign up bonus once recruit joins and
                    makes first purchase using chirpyest
                  </p>
                )}
                {isLoading.userRecruits ? (
                  <CHRSpinner />
                ) : (
                  <>
                    {!isPhone ? (
                      <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={classes.tableHeader}
                                style={{ width: '25%' }}
                              >
                                <Typography variant="subtitle1">
                                  user
                                </Typography>
                              </TableCell>
                              <TableCell
                                className={classes.tableHeader}
                                style={{ width: '25%' }}
                              >
                                <Typography variant="subtitle1">
                                  joined
                                </Typography>
                              </TableCell>
                              <TableCell
                                className={classes.tableHeader}
                                style={{ width: '25%' }}
                              >
                                <Typography variant="subtitle1">
                                  used chirpyest
                                </Typography>
                              </TableCell>
                              {appstate?.userProfile?.isReferralBonus && (
                                <TableCell
                                  className={classes.tableHeader}
                                  style={{ width: '25%' }}
                                >
                                  <Typography variant="subtitle1">
                                    referral bonus paid
                                  </Typography>
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {userRecruits.data.map((row: any) => (
                              <StyledTableRow key={`${row.id}`}>
                                <TableCell>
                                  <Link
                                    to={`/s/${
                                      appstate.userProfile.isPro
                                        ? row.username
                                        : username
                                    }`}
                                    style={{ color: COLORS.black }}
                                    target="_blank"
                                  >
                                    {appstate.userProfile.isPro
                                      ? row.username || row.name
                                      : 'only visible to pro users'}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  {moment(row.created_at).format('MM/DD/YY')}
                                </TableCell>
                                <TableCell>{row.used_chirpyest}</TableCell>
                                {appstate?.userProfile?.isReferralBonus && (
                                  <TableCell>{row.paid_bonus}</TableCell>
                                )}
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      <div>
                        {userRecruits.data.map((row: any) => (
                          <Grid container className={classes.tableCard}>
                            <Grid item sm={6} xs={6}>
                              <Typography
                                variant="subtitle1"
                                className={classes.fieldName}
                              >
                                user
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                              <Typography
                                variant="subtitle1"
                                className={classes.fieldValue}
                              >
                                <Link
                                  to={`/s/${
                                    appstate.userProfile.isPro
                                      ? row.username
                                      : userName
                                  }`}
                                  style={{ color: COLORS.black }}
                                  target="_blank"
                                >
                                  {appstate.userProfile.isPro
                                    ? row.username || row.name
                                    : 'only visible to pro users'}
                                </Link>
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                              <Typography
                                variant="subtitle1"
                                className={classes.fieldName}
                              >
                                joined
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                              <Typography
                                variant="subtitle1"
                                className={classes.fieldValue}
                              >
                                {moment(row.created_at).format('MM/DD/YY')}
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                              <Typography
                                variant="subtitle1"
                                className={classes.fieldName}
                              >
                                used chirpyest
                              </Typography>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                              <Typography
                                variant="subtitle1"
                                className={classes.fieldValue}
                              >
                                {row.used_chirpyest}
                              </Typography>
                            </Grid>
                            {appstate?.userProfile?.isReferralBonus && (
                              <>
                                <Grid item sm={6} xs={6}>
                                  <Typography
                                    variant="subtitle1"
                                    className={classes.fieldName}
                                  >
                                    referral bonus paid
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                  <Typography
                                    variant="subtitle1"
                                    className={classes.fieldValue}
                                  >
                                    {row.paid_bonus}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        ))}
                      </div>
                    )}
                    {!userRecruits.finishedLoading && (
                      <CHROutlinedButton
                        label={t('dashboard.loadMore')}
                        font={FONTS.Graphik.GraphikMedium}
                        onClick={getUserRecruits}
                      />
                    )}
                    {userRecruitsError && (
                      <p className={classes.customErrorMsg}>
                        {userRecruitsError}
                      </p>
                    )}
                  </>
                )}
              </section>
              {chartError && (
                <div style={{ marginTop: 100, display: 'none' }}>
                  <p className={classes.subtitles}>sales channels</p>
                  <p className={classes.customErrorMsg}>{chartError}</p>
                </div>
              )}
              {chartResult && isThereCashBack && (
                <div style={{ marginTop: 100, display: 'none' }}>
                  <p className={classes.subtitles}>sales channels</p>
                  <div className={classes.chartContainer}>
                    <div className={classes.doughnut}>
                      <Doughnut
                        options={{
                          legend: {
                            display: false,
                          },
                          hover: { mode: false },
                          tooltips: { enabled: false },
                        }}
                        data={{
                          labels: [
                            'Shared product link',
                            'Shopping board',
                            'Browser extension',
                            'Other',
                          ],
                          datasets: [
                            {
                              data: [
                                chartResult.sharedProduct.percentage,
                                chartResult.board.percentage,
                                chartResult.extension.percentage,
                                chartResult.other.percentage,
                              ],
                              backgroundColor: [
                                '#BFD3D0',
                                '#85ACA3',
                                '#2E7C86',
                                '#175972',
                              ],
                              borderColor: [
                                '#BFD3D0',
                                '#85ACA3',
                                '#2E7C86',
                                '#175972',
                              ],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        width={355}
                        height={355}
                      />
                    </div>
                    <div className={classes.chartDetails}>
                      <div className={classes.doughnutItem}>
                        <div
                          className={`${classes.firstChannelSquare} ${classes.square}`}
                        />
                        <Typography
                          style={{ textTransform: 'uppercase' }}
                          variant="button"
                        >
                          Shared product link:{' '}
                          {chartResult.sharedProduct.percentage}%{' '}
                          <span className={classes.lightText}>
                            (${chartResult.sharedProduct.money},{' '}
                            {chartResult.sharedProduct.items}{' '}
                            {chartResult.sharedProduct.items === 1
                              ? 'item'
                              : 'items'}
                            )
                          </span>
                        </Typography>
                      </div>
                      <div className={classes.doughnutItem}>
                        <div
                          className={`${classes.seconedChannelSquare} ${classes.square}`}
                        />
                        <Typography
                          style={{ textTransform: 'uppercase' }}
                          variant="button"
                        >
                          Shopping board: {chartResult.board.percentage}%{' '}
                          <span className={classes.lightText}>
                            (${chartResult.board.money},{' '}
                            {chartResult.board.items}{' '}
                            {chartResult.board.items === 1 ? 'item' : 'items'})
                          </span>
                        </Typography>
                      </div>
                      <div className={classes.doughnutItem}>
                        <div
                          className={`${classes.thirdChannelSquare} ${classes.square}`}
                        />
                        <Typography
                          style={{ textTransform: 'uppercase' }}
                          variant="button"
                        >
                          browser extension: {chartResult.extension.percentage}%{' '}
                          <span className={classes.lightText}>
                            (${chartResult.extension.money},{' '}
                            {chartResult.extension.items}{' '}
                            {chartResult.extension.items === 1
                              ? 'item'
                              : 'items'}
                            )
                          </span>
                        </Typography>
                      </div>
                      <div className={classes.doughnutItem}>
                        <div
                          className={`${classes.othersChannelSquare} ${classes.square}`}
                        />
                        <Typography
                          style={{ textTransform: 'uppercase' }}
                          variant="button"
                        >
                          other: {chartResult.other.percentage}%{' '}
                          <span className={classes.lightText}>
                            (${chartResult.other.money},{' '}
                            {chartResult.other.items}{' '}
                            {chartResult.other.items === 1 ? 'item' : 'items'})
                          </span>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </TabPanel>
          </div>
        </ContentContainer>
        <ContentContainer>
          <TabPanel value={tabValue} index={appstate.userProfile.isPro ? 2 : 1}>
            <PaymentInfoTab
              updateName={updateFullName}
              updateUsername={updateUsername}
              {...rest}
            />
          </TabPanel>
        </ContentContainer>
        <ContentContainer>
          <TabPanel value={tabValue} index={appstate.userProfile.isPro ? 3 : 2}>
            {/* <div
              onClick={() => navigate(ROUTES.paypalSettings)}
              className={classes.arrBtnDown}
            >
              <ArrowDownwardRoundedIcon />
            </div> */}
            <SettingsTab
              updateName={updateFullName}
              updateUsername={updateUsername}
              {...rest}
            />
          </TabPanel>
        </ContentContainer>
      </section>
      <AddExtensionPopup
        open={isAddExtensionPopUpOpen}
        handleClose={closeAddExtensionPopUp}
      />
    </Layout>
  )
}

export default withTranslation()(DashboardPage)
