import React from 'react'
import { styles } from './styles'
import { Typography } from '@material-ui/core'

const ProgressBar = props => {
  const classes = styles(props)
  const { completed, base } = props
  return (
    <div className={classes.container}>
      {(completed / base) * 100 > 20 ? (
        <div className={classes.child}>
          <Typography
            variant="subtitle1"
            className={classes.completedText}
          >{`$${completed} ${
            base > completed
              ? '($' + Number(base - completed).toFixed(2) + ' TO GO!)'
              : ''
          }`}</Typography>
        </div>
      ) : (
        <div className={classes.smallCompleted}>
          <p className={classes.colorBg}></p>
          <Typography
            variant="subtitle1"
            className={classes.smallCompletedText}
          >{`$${completed} ${'($' +
            Number(base - completed).toFixed(2) +
            ' TO GO!)'}`}</Typography>
        </div>
      )}
      <Typography
        variant="subtitle1"
        className={classes.baseText}
      >{`$${base}`}</Typography>
    </div>
  )
}

export default ProgressBar
