import React, { useState, useEffect } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { Typography, Grid } from '@material-ui/core'
import { navigate } from 'gatsby'
import CHRArrowButton from '../../../components/arrowButton'
import { styles } from './styles'
// import { ENDPOINTS } from '../../../constants'
import { useAppState } from '../../../appState'
import { generateTrackingLink } from '../../../../utils/brandsHelper'
import BrandItemUser from '../../brandItemUser'
import BrandItem from '../../brandItem'
import BrandsListComponent from '../../brandsList'

interface CardProps {
  t: TFunction
  brands?: any
}
const BrandsList = ({ t, brands }: CardProps) => {
  const classes = styles()
  const [appState] = useAppState()
  const [homeProducts, setHomeProducts] = useState([])
  const [topProducts, setTopProducts] = useState([])
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)

  const [error, setError] = useState('')

  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  function getElements(arr, s, e) {
    const arrNew = []
    for (let i = s; i <= e; i++) {
      let index = i
      if (i < 0) {
        index = arr.length + i
      }
      arrNew.push(arr[index])
    }
    return arrNew
  }

  const forwardArrowClicked = () => {
    let startIndex = start + 4
    let endIndex
    if (startIndex === topProducts.length - 1) {
      startIndex = -1
    } else if (startIndex > topProducts.length - 1) {
      startIndex = startIndex - topProducts.length - 1
    }
    startIndex = startIndex % topProducts.length
    endIndex = startIndex + 5
    if (endIndex > topProducts.length - 1) {
      endIndex = endIndex - topProducts.length
      startIndex = endIndex - 5
    }
    setHomeProducts(getElements(topProducts, startIndex, endIndex))
    setStart(startIndex)
    setEnd(endIndex)
  }

  const backArrowClicked = () => {
    let startIndex = start - 4
    startIndex = startIndex % topProducts.length
    const endIndex = startIndex + 5
    setHomeProducts(getElements(topProducts, startIndex, endIndex))
    setStart(startIndex)
    setEnd(endIndex)
  }

  useEffect(() => {
    let endIndex = end
    if (deviceType() === 'mobile') {
      endIndex = 4
    } else if (deviceType() === 'tablet') {
      endIndex = 18
    } else {
      endIndex = 6
    }
    setEnd(endIndex)
    setTopProducts(brands)
    setHomeProducts(brands.slice(start, endIndex))
  }, [brands])

  return (
    !error && (
      <div>
        <div className={classes.trendingContainer}>
          <Typography
            variant="h1"
            className={classes.mobileEdgeSpace + ' ' + classes.trendingHeading}
          >
            {t('memberHome.brandsList')}
          </Typography>
          <Typography
            variant="subtitle1"
            className={
              classes.mobileEdgeSpace2 + ' ' + classes.trendingSubtitle
            }
          >
            {t('memberHome.brandsListSubtitle')}
          </Typography>
        </div>

        {deviceType() === 'mobile' && (
          <>
            <BrandsListComponent>
              {homeProducts.map((brand: any, index: number) => {
                return (
                  <BrandItem
                    brandLogo={brand.src}
                    name={brand.brandName}
                    cashBack={brand.commission}
                    link={generateTrackingLink(
                      brand,
                      {
                        userId: appState.userId,
                        userName: appState.username,
                      },
                      '',
                      '',
                      Boolean(appState.userId)
                    )}
                    key={index}
                    isCardOnLanding
                  />
                )
              })}
            </BrandsListComponent>
            <div className={classes.trendingContainer3}>
              <div className={classes.CHRbArrowButton}>
                <CHRArrowButton
                  label="see all brands"
                  onClick={() => navigate('/brands')}
                  hoverEffects={true}
                />
              </div>
            </div>
          </>
        )}

        {deviceType() === 'tablet' && (
          <>
            <div className={classes.tabletMode}>
              <Grid
                container
                spacing={3}
                style={{
                  flexWrap: 'nowrap',
                  marginBottom: '10px',
                }}
              >
                {homeProducts.map((brand: any, index: number) => (
                  <Grid
                    item
                    lg={2}
                    md={3}
                    sm={6}
                    xs={6}
                    key={brand.id}
                    style={{ minWidth: '229.5px' }}
                  >
                    <BrandItemUser
                      brandLogo={brand.src}
                      name={brand.brandName}
                      cashBack={brand.commission}
                      link={generateTrackingLink(
                        brand,
                        {
                          userId: appState.userId,
                          userName: appState.username,
                        },
                        '',
                        '',
                        Boolean(appState.userId)
                      )}
                      key={index}
                      isCardOnLanding
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
            <div className={classes.trendingContainer3}>
              <div className={classes.CHRbArrowButton}>
                <CHRArrowButton
                  label="see all brands"
                  onClick={() => navigate('/brands')}
                  hoverEffects={true}
                />
              </div>
            </div>
          </>
        )}

        {deviceType() === 'desktop' && (
          <div className={classes.trendingContainer2}>
            <div className={classes.topProductsContainer}>
              <div className={classes.arrowMaxHeight}>
                <CHRArrowButton
                  isLeftArrow
                  label=""
                  onClick={backArrowClicked}
                  hoverEffects={true}
                />
              </div>

              <Grid
                container
                spacing={3}
                style={{ flexWrap: 'nowrap', overflow: 'hidden' }}
              >
                {homeProducts.map((brand: any, index: number) => (
                  <Grid
                    item
                    lg={2}
                    md={3}
                    sm={6}
                    xs={6}
                    key={brand.id}
                    style={{ minWidth: '229.5px' }}
                  >
                    <BrandItemUser
                      brandLogo={brand.src}
                      name={brand.brandName}
                      cashBack={brand.commission}
                      link={generateTrackingLink(
                        brand,
                        {
                          userId: appState.userId,
                          userName: appState.username,
                        },
                        '',
                        '',
                        Boolean(appState.userId)
                      )}
                      key={index}
                      isCardOnLanding
                    />
                  </Grid>
                ))}
              </Grid>
              <div className={classes.arrowMaxHeight}>
                <CHRArrowButton
                  label=""
                  onClick={forwardArrowClicked}
                  hoverEffects={true}
                />
              </div>
            </div>
            <div className={classes.trendingContainer3}>
              <div className={classes.CHRbArrowButton}>
                <CHRArrowButton
                  label="see all brands"
                  onClick={() => navigate('/brands')}
                  hoverEffects={true}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  )
}

export default withTranslation()(BrandsList)
