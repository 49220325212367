import { makeStyles } from '@material-ui/core/styles'
import { COLORS, BREAKPOINTS, FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  trendingHeading: {
    marginBottom: 10,
    fontFamily: 'Schnyder XL Light',
    fontSize: '3.75rem',
    fontWeight: 300,
    display: 'block',
    lineHeight: '86px',
    letterSpacing: '-0.01562em',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '2.25rem',
      lineHeight: '40px',
    },
  },
  trendingSubtitle: {
    fontSize: '16px !important',
    fontFamily: 'Graphik Light !important',
    fontWeight: 400,
    letterSpacing: '0.00938em',
    lineHeight: 1.75,
    marginBottom: 25,
  },
  trendingContainer: {
    width: '100%',
    paddingTop: 100,
    overflow: 'hidden',
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      paddingTop: 50,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      paddingTop: 50,
      ['& .MuiGrid-spacing-xs-5']: {
        margin: 0,
        width: '100%',
        paddingLeft: '20px',
      },
      ['& .MuiGrid-spacing-xs-5 > .MuiGrid-item']: {
        padding: 0,
      },
    },
  },
  mobileEdgeSpace: {
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      fontSize: '2.5rem',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '3.75rem',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '2.25rem',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  mobileEdgeSpace2: {
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  CHRbArrowButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
    },
  },
}))
