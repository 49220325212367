import { colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, WIDTH_DIMENSIONS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  root: {
    opacity: 1,
    overflow: 'initial',
    fontFamily: FONTS.Graphik.GraphikRegular,
    textTransform: 'lowercase',
    // color: COLORS.lightGrey,
    backgroundColor: COLORS.whiteStain,
    border: 'none',
    fontSize: '0.75rem',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '0.857rem',
    },
  },
  selected: ({
    selectedBgColor = theme.palette.common.black,
  }: {
    selectedBgColor?: string
  }) => ({
    backgroundColor: selectedBgColor,
    color: theme.palette.common.white,
    border: 'none',
    '& + $root': {
      zIndex: 1,
    },
    '& + $root:before': {
      opacity: 0,
    },
  }),
  proSelected: ({
    selectedBgColor = COLORS.watermelonPink,
  }: {
    selectedBgColor?: string
  }) => ({
    backgroundColor: selectedBgColor,
    color: theme.palette.common.white,
    border: 'none',
    '& + $root': {
      zIndex: 1,
    },
    '& + $root:before': {
      opacity: 0,
    },
  }),
  personalInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  personalInfoForm: {
    display: 'flex',
    alignItems: 'center',
    margin: `40px 0`,
    position: 'relative',
  },
  paypalBtn: {
    marginTop: 85,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      marginTop: 45,
    },
  },
  cashBackSection: {
    marginTop: 100,
    marginBottom: 100,
    margin: 'auto',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      width: '100%',
      margin: '60px 0',
    },
  },
  form: {
    width: '50%',
    margin: 'auto',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      width: '100%',
    },
  },
  formComing: {
    width: '100%',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.common.black,
    margin: '40px 0',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '40px 0',
  },
  subTitle: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '5rem',
    lineHeight: 1.3,
    margin: '40px 0 0',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '2.85rem',
    },
  },
  subTitleBetween: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '5rem',
    lineHeight: 1.3,
    margin: '0',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '2.85rem',
    },
  },
  subTitleTwo: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '5rem',
    lineHeight: 1.3,
    margin: '0 0 40px',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '2.85rem',
    },
  },
  subTitleThree: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '5rem',
    lineHeight: 1.3,
    margin: '40px 0',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '2.85rem',
    },
  },
  disclaimer: {
    color: '#ABABAB',
    fontSize: '0.875rem',
    fontFamily: 'Graphik Light',
    lineHeight: '1.64',
  },
  tableWrapper: {
    width: '100%',
    overflowX: 'auto',
  },
  tableHeader: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '2rem',
    lineHeight: 1.3,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '1.25rem',
    },
  },
  tableRow: {
    fontSize: '1rem',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '0.857rem',
    },
  },
  lightButton: {
    border: 'solid',
    borderWidth: 2,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 54,
    cursor: 'pointer',
    padding: '10px 15px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    color: theme.palette.primary.main,
    position: 'relative',
  },
  lightButtonLabel: {
    display: 'block',
    width: '90%',
    marginLeft: '5%',
  },
  disabled: {
    fontFamily: FONTS.Graphik.GraphikLight,
    color: COLORS.lightGrey,
    fontSize: '1rem',
    marginBottom: 20,
  },
  disabledButton: {
    fontFamily: FONTS.Graphik.GraphikLight,
    backgroundColor: COLORS.ashGrey,
    color: theme.palette.common.white,
    textAlign: 'center',
    padding: 20,
    fontSize: '0.75rem',
    height: 54,
    margin: '20px 0 120px 0',
  },
  dashboardContainer: {
    margin: 'auto',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      width: '90%',
    },
  },
  paragraph: {
    fontFamily: FONTS.Graphik.GraphikLight,
    fontSize: '1rem',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '1.14rem',
    },
  },
  tabsSection: {
    width: '85%',
    margin: 'auto',
    maxWidth: WIDTH_DIMENSIONS.max,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      width: '100%',
    },
  },
  tabsSection2: {
    width: '85%',
    margin: 'auto',
    maxWidth: WIDTH_DIMENSIONS.max,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      width: '100%',
    },
    ['& .MuiTabs-flexContainer > button:first-child']: {
      marginRight: 5,
      marginLeft: 5,
    },
    ['& .MuiTabs-flexContainer > button:last-child']: {
      marginRight: 5,
      // marginLeft: 5,
    },
    ['& .MuiTabs-root']: {
      marginBottom: 5,
    },
    ['& .MuiTabs-indicator']: {
      backgroundColor: 'transparent',
    },
  },
  rightInfo: {
    marginLeft: 20,
  },
  userName: {
    marginBottom: '0px !important',
    lineHeight: 1.2,
  },
  subtitles: {
    margin: 0,
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '3.75rem',
    lineHeight: 1.2,
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '2.57rem',
    },
  },
  yourAccount: {
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '1.875rem',
    lineHeight: 1.15,
    marginTop: 0,
  },
  paymentInfo: {
    width: '77%',
    margin: '60px auto 0 auto',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      width: '70%',
    },
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      width: '100%',
    },
    ['& label .MuiTypography-subtitle1']: {
      fontSize: 14,
      marginTop: '-3px',
    },
    ['& .MuiFormControl-root label.MuiInputLabel-formControl']: {
      // fontSize: '10px !important',
    },
  },
  settingsWrapper: {
    width: '85%',
    margin: '78px auto 0 auto',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      width: '100%',
    },
  },
  titlesSpacing: {
    margin: '100px 0 40px 0',
  },
  titlesSpacing2: {
    margin: '70px 0 40px 0',
  },
  comingSoon: {
    margin: 0,
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '1.75rem',
    lineHeight: 1.2,
  },
  inputSpacing: {
    marginBottom: `20px !important`,
  },
  paymentInputSpacing: {
    marginBottom: '20px !important',
    marginTop: '20px !important',
  },
  paypalBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    cursor: 'pointer',
  },
  paypalImg: {
    marginTop: 40,
  },
  editPaypalButton: {
    width: 24,
    height: 24,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '& img': {
      width: 18,
      height: 18,
    },
  },
  background: {
    backgroundColor: COLORS.whiteStain,
    padding: '20px 0',
    margin: '20px 0',
    '& p': {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 0,
    },
  },
  inputWrapper: {
    position: 'relative',
  },
  errorMsg: {
    color: COLORS.dangerRed,
    position: 'absolute',
    left: 0,
    top: -20,
    fontSize: 14,
    margin: 0,
  },
  successMsg: {
    color: COLORS.successGreen,
    position: 'absolute',
    left: 0,
    fontSize: 14,
    margin: 0,
  },
  icon: {
    position: 'absolute',
    top: '30%',
    left: '5%',
  },
  uploadInput: {
    display: 'none',
  },
  avatarErrorMsg: {
    marginBottom: 15,
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      marginRight: 15,
      marginBottom: 0,
    },
  },
  avatarCircle: {
    width: 100,
    minWidth: 100,
    backgroundColor: COLORS.black,
    borderRadius: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  replaceImageText: {
    paddingLeft: 15,
  },
  uploadIcon: {
    width: 18,
    height: 18,
    position: 'absolute',
    left: '5%',
    top: '25%',
  },
  uploadError: {
    position: 'absolute',
    bottom: -35,
    left: 0,
  },
  margin: {
    marginTop: 20,
  },
  passwordField: {
    display: 'flex',
    position: 'relative',
  },
  passVisibility: {
    position: 'absolute',
    right: '3%',
    top: '26%',
    color: '#5e676f',
    cursor: 'pointer',
    fontSize: 13,
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      justifyContent: 'initial',
    },
  },
  table: {
    marginTop: 20,
    marginBottom: 20,
  },
  underLinedText: {
    textDecoration: 'underline',
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.common.black,
  },
  fieldName: {
    marginBottom: 10,
    fontFamily: FONTS.Graphik.GraphikRegular,
  },
  fieldValue: {
    marginBottom: 10,
  },
  tableCard: {
    backgroundColor: COLORS.whiteStain,
    marginTop: 10,
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: ' 50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    padding: theme.spacing(2.5, 2.5, 5),
  },
  doughnut: {
    maxWidth: 400,
    width: '100%',
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      maxWidth: 335,
    },
  },
  lightText: {
    fontFamily: FONTS.Graphik.GraphikRegular,
  },
  square: {
    width: 40,
    height: 40,
    marginRight: 20,
  },
  firstChannelSquare: {
    backgroundColor: '#BFD3D0',
  },
  seconedChannelSquare: {
    backgroundColor: '#85ACA3',
  },
  thirdChannelSquare: {
    backgroundColor: '#2E7C86',
  },
  othersChannelSquare: {
    backgroundColor: '#175972',
  },
  doughnutItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
  },
  chartContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 0,
    marginTop: 40,
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      marginLeft: 100,
      flexDirection: 'row',
    },
  },
  chartDetails: {
    marginLeft: 0,
    marginTop: 40,
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      marginLeft: 85,
      marginTop: 0,
    },
  },
  socialInput: {
    fontSize: '12px !important',
    paddingLeft: '72px !important',
    margin: '0 0 20px !important',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '1rem !important',
    },
  },
  socialImgBox: {
    width: '54px',
    background: '#000',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    padding: '12px',
    textAlign: 'center',
    ['& img']: {
      maxWidth: '100%',
    },
  },
  socialBtn: {
    margin: '0 0 40px !important',
  },
  connectError: {
    color: '#FF3860',
    fontSize: '0.85rem',
    margin: '-10px 0 20px',
  },
  userAvatar: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    display: 'block',
    width: 100,
    height: 100,
    objectFit: 'cover',
    borderRadius: '50%',
  },
  profileActive: {
    position: 'absolute',
    bottom: -6,
    left: 0,
  },
  avatarWrapper: {
    position: 'relative',
  },
  textFieldColor: {
    backgroundColor: `${COLORS.whiteStain} !important`,
    width: '100% !important',
    fontFamily: `${FONTS.Graphik.GraphikRegular} !important`,
    letterSpacing: `1.2 !important`,
    fontSize: '0.75rem !important',
    outline: 'none !important',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: '1rem !important',
    },
  },
  venmoInputPlaceholder: {
    [`& input::placeholder`]: {
      opacity: 1,
    },
  },
  textFieldLabelFormControl: {
    left: `8px !important`,
  },
  textFieldLabelRoot: {
    color: `#000 !important`,
    letterSpacing: `1.2 !important`,
    fontSize: '0.75rem !important',
  },
  textFieldLabelShrink: {
    fontFamily: `${FONTS.Graphik.GraphikLight} !important`,
    fontSize: `14px !important`,
    letterSpacing: `0.9 !important`,
    color: `#000 !important`,
  },
  notchedOutline: {
    borderColor: COLORS.dangerRed,
  },
  disabledTextField: {
    backgroundColor: `${COLORS.whiteStain} !important`,
  },
  textFieldInput: {
    paddingLeft: `20px !important`,
    paddingRight: `20px !important`,
  },
  arrBtnDown: {
    position: 'absolute',
    top: '7%',
    right: 0,
    border: `2px black solid`,
    borderRadius: `27px`,
    width: 54,
    height: 54,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    cursor: 'pointer',
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      display: 'none',
    },
  },
  venmoImg: {
    marginTop: 40,
    width: 137,
    height: 26,
  },
  paymentErrorMsg: {
    color: COLORS.dangerRed,
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 14,
    margin: 0,
  },
  customErrorMsg: {
    color: COLORS.dangerRed,
    fontSize: 14,
    margin: 0,
  },
  imageContainer: {
    width: 30,
    height: 31,
    marginRight: 15,
  },
  textContainer: {
    width: 199,
    fontSize: 12,
    fontFamily: FONTS.Graphik.GraphikLight,
    fontWeight: 300,
  },
  enterUsernamePopup: {
    zIndex: 1,
  },
  flex: {
    display: 'flex',
    width: '100%',
    ['& .MuiTabs-root']: {
      width: '100%',
    },
  },
  subtitles2: {
    margin: 0,
    marginBottom: 40,
    fontFamily: FONTS.Schnyder.SchnyderXLLight,
    fontSize: '3.75rem',
    lineHeight: 1.2,

    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      fontSize: '2.25rem',
    },
  },
  overPayment: {
    fontSize: '30px !important',
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    fontWeight: 400,
    lineHeight: 1.75,
    marginBottom: 15,
    marginLeft: 100,
    letterSpacing: '0.00938em',
    textTransform: 'lowercase',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '20px !important',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '20px !important',
      marginLeft: 35,
    },
  },
  overPaymentTwo: {
    fontSize: '30px !important',
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    fontWeight: 400,
    lineHeight: 1.75,
    marginBottom: 15,
    // marginLeft: 10,
    letterSpacing: '0.00938em',
    textTransform: 'lowercase',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '20px !important',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '20px !important',
      // marginLeft: 10,
    },
  },
}))
