import { Grid, Typography } from '@material-ui/core'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { useAppState } from '../../appState'
import { ROUTES } from '../../constants'
import CHRArrowButton from '../arrowButton'
import BlogItem from '../homeSections/blogItem'
import { styles } from './styles'

const ChirpyestBlog = ({ t, blogs }) => {
  const classes = styles()
  const [appState] = useAppState()
  return (
    <div className={classes.sectionContainer}>
      <span className={classes.mobileEdgeSpace + ' ' + classes.mainHeading}>
        <a
          href={ROUTES.blog}
          style={{ color: 'black', textDecoration: 'none' }}
        >
          {t('shared.chirpyestBlog')}
        </a>
      </span>
      {/* <Typography variant="h1" className={classes.mobileEdgeSpace}>
        <a
          href={ROUTES.blog}
          style={{ color: 'black', textDecoration: 'none' }}
        >
          {t('shared.chirpyestBlog')}
        </a>
      </Typography> */}
      <Grid container justifyContent="space-between" spacing={3}>
        {blogs
          .slice(0, appState?.username ? 4 : 2)
          .map(({ title, image, link }) => (
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className={classes.blogItemContainer}
            >
              <BlogItem
                blogImage={image}
                blogTitle={title}
                link={link}
                isBlog={'blog'}
              />
            </Grid>
          ))}
      </Grid>
      <div className={classes.CHRblogButton}>
        <CHRArrowButton
          label={appState.username ? 'see all posts' : 'chirpyest blog'}
          link={ROUTES.blog}
          outerLink={true}
          hoverEffects={true}
        />
      </div>
    </div>
  )
}

export default withTranslation()(ChirpyestBlog)
