import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS } from '../../constants'

export const styles = makeStyles(() => ({
  mainHeading: {
    display: 'block',
    fontSize: '3.75rem',
    lineHeight: '86px',
    fontFamily: 'Schnyder XL Light',
    fontWeight: 300,
    marginBottom: 35,
    letterSpacing: '-0.01562em',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      fontSize: '2.25rem',
      lineHeight: '40px',
    },
  },
  sectionContainer: {
    width: '100%',
    overflow: 'hidden',
    paddingTop: 100,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      paddingTop: 50,
    },

    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      paddingTop: 60,
      ['& > .MuiGrid-spacing-xs-3']: {
        margin: 0,
        width: '100%',
      },
      ['& > .MuiGrid-spacing-xs-3 > .MuiGrid-item']: {
        padding: 0,
      },
    },
  },
  blogItemContainer: {
    width: '100%',
    // maxWidth: 706,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      maxWidth: '100%',
    },
  },
  mobileEdgeSpace: {
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  CHRblogButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
    },
  },
}))
