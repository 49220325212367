import React from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import inactiveIcon from '../../assets/images/chirpyest-logo.svg'
import activeIconWithDollar from '../../assets/images/Chirpyest_Member_page_icon@2x.png'
import { styles } from '../popups/styles'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { ROUTES } from '../../constants'
import { Link } from 'gatsby'
import CHRButton from './../button'
// import { trackAddToChirpyestButtonClickEvent } from '../../../utils/trackingEvents'
// import { detect } from 'detect-browser'

const style = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.common.black,
    },
  })

export interface DialogTitleProps extends WithStyles<typeof style> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(style)((props: DialogTitleProps) => {
  const { classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography {...other}>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))(MuiDialogContent)

interface PopOutProps {
  t: TFunction
  open: boolean
  handleClose: any
  onNavigateToExtStore: any
}

const WelcomePopOut = ({
  t,
  open,
  handleClose,
  onNavigateToExtStore,
}: PopOutProps) => {
  const classes = styles()

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.popout}
    >
      <DialogTitle onClose={handleClose} />
      <DialogContent>
        <Typography
          variant="h2"
          component="h2"
          align="center"
          className={classes.welcomePopUpTitle}
        >
          {t('shared.welcome')}
        </Typography>
        <div className={classes.welcomeDialog}>
          <article className={classes.articleContent}>
            <img
              src={inactiveIcon}
              alt={t('imageAlts.inactive')}
              className={classes.welcomePopUpIcon}
            />{' '}
            <p className={classes.welcomPopUpParagraph}>
              {t('popUps.text.thisMeansInactive')}
            </p>
          </article>
          <article
            className={[
              classes.articleContent,
              classes.articleContentSecondParagraph,
            ].join(' ')}
          >
            <img
              src={activeIconWithDollar}
              alt={t('imageAlts.active')}
              className={classes.welcomePopUpIcon}
            />
            <p className={classes.welcomPopUpParagraph}>
              {t('popUps.text.thisMeansActive')}
            </p>
          </article>
          <article className={classes.articleContent}>
            <div className={classes.activateCashbackBtn}>
              <CHRButton
                label={t('shared.activateCashback')}
                customStyle={`${classes.smallButton}`}
                customTextStyle={`${classes.smallText}`}
              />
            </div>
            <p className={classes.welcomPopUpParagraph}>
              {t('popUps.text.doThisForCashback')}
            </p>
          </article>
          <section className={classes.articleContentLinks}>
            <p
              className={classes.addExtensionText}
              onClick={onNavigateToExtStore}
            >
              {t('shared.addBrowserExtension')}
            </p>
          </section>
          <section className={classes.articleContentLinks}>
            <Link
              rel="preconnect"
              to={ROUTES.howItWorks}
              className={classes.link}
            >
              {t('shared.howItWorks')}
            </Link>
            <Link to={ROUTES.brands} className={classes.link}>
              {t('shared.brands')}
            </Link>
          </section>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default withTranslation()(WelcomePopOut)
