import { makeStyles } from '@material-ui/core/styles'
import { COLORS, BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  trendingContainer: {
    width: '90%',
    margin: '0 auto',
    position: 'relative',
  },
  container: {
    width: '100%',
    maxWidth: 343,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: 166,
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    //overflow: 'hidden',
    '&:hover': {
      '& $shopButton': {
        opacity: 1,
      },
    },
    height: 269.59,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      height: 269.59,
      overflow: 'hidden',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      height: 219,
      overflow: 'hidden',
    },
  },
  anchorWidth: {
    width: '100%',
  },
  image: {
    display: 'block',
    // width: 'auto',
    // height: 'auto',
    width: '100%',
    maxWidth: '100%',
    maxHeight: 269.59,
    minHeight: 269.59,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      maxHeight: 269.59,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      maxHeight: 219,
      minHeight: 219,
      overflow: 'hidden',
    },
  },
  heart: {
    width: 40,
    height: 40,
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    borderRadius: '50%',
    backgroundColor: '#FAFBFD',
    textAlign: 'center',
    padding: 6,
    cursor: 'pointer',
    border: 'none',
  },
  deleteIcon: {
    width: 40,
    height: 40,
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    borderRadius: '50%',
    backgroundColor: '#FAFBFD',
    textAlign: 'center',
    padding: 6,
    cursor: 'pointer',
    border: 'none',
  },
  productInfo: {
    fontFamily: 'Graphik Regular !important',
    fontSize: '0.75rem !important',
  },
  cashBack: {
    fontFamily: 'Graphik Regular',
    fontSize: '0.75rem',
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgb(250,251,253)',
    opacity: 0.8,
    width: '100%',
    // maxWidth: 343,
    textAlign: 'center',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    marginBottom: 0,
  },
  topItemCashBack: {
    display: 'flex !important',
    fontFamily: 'Graphik Regular',
    fontSize: '0.75rem',
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgb(250,251,253)',
    opacity: 0.8,
    width: '100%',
    maxWidth: 343,
    textAlign: 'center',
    marginBottom: 0,
  },
  productName: {
    textDecoration: 'none',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  price: {},
  nameAndPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
    [`@media (max-width: ${BREAKPOINTS.lg - 1}px)`]: {
      // paddingRight: 10,
      // paddingLeft: 10,
    },
  },
  retailerName: {
    marginBottom: 0,
    fontSize: '0.75rem',
    [`@media (max-width: ${BREAKPOINTS.lg - 1}px)`]: {
      // paddingRight: 10,
      // paddingLeft: 10,
    },
  },
  CHRbutton: {
    float: 'right',
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '2rem',
    width: '100%',
    maxWidth: 1190,
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
  shopButton: {
    position: 'absolute',
    top: '50%',
    width: '50%',
    margin: 'auto',
    right: 0,
    left: 0,
    background: COLORS.white,
    padding: 10,
    opacity: 0,
    transition: 'opacity .35s ease',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    color: 'inherit',
    border: '2px black solid',
  },
  boardBtn: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    height: 54,
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    cursor: 'pointer',
  },
  modalInfo: {
    paddingTop: 30,
    width: '100%',
    maxWidth: 400,
    margin: 'auto',
  },
  modalInfoTitle: {
    marginBottom: 10,
  },
  modalInfoPrice: { display: 'flex', justifyContent: 'flex-end' },
  modalInfoDescription: {
    marginTop: 20,
    marginBottom: 20,
    maxHeight: 300,
    scrollBehavior: 'smooth',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  modalButton: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    padding: 20,
    textDecoration: 'none',
  },
  addToShoppingBoard: {
    cursor: 'pointer',
    '&:disabled': {
      border: `1px solid #999999`,
      backgroundColor: `#cccccc`,
      color: `#666666`,
    },
  },
  blackBgButton: {
    color: COLORS.white,
    backgroundColor: COLORS.black,
    marginBottom: 20,
  },
  whiteBgButton: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    border: `2px solid ${COLORS.black}`,
    marginBottom: 10,
  },
  addToboardText: {
    margin: 'auto !important',
  },
  addToboardContainer: {
    display: 'flex',
  },
  socialTitle: {
    textAlign: 'center',
    marginTop: 10,
  },
  socialButton: {
    width: 50,
    height: 50,
    border: `2px solid ${COLORS.black}`,
    borderRadius: '50%',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    margin: '0 6px',
    textAlign: 'center',
    padding: 0,
    fontSize: 25,
    lineHeight: '50px',
  },
  socialIcon: {
    width: '100%',
    maxWidth: 24,
  },
  closeButton: {
    position: 'absolute',
    right: 30,
    top: 30,
    cursor: 'pointer',
  },
  brandLink: {
    color: COLORS.black,
    '&:active': {
      color: COLORS.black,
    },
  },
  productImage: {
    width: '100%',
    maxWidth: 561,
    height: 798,
    objectFit: 'contain',
    margin: 'auto',
    userSelect: 'none',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      maxWidth: 376,
      height: 440,
    },
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  salePrice: {
    position: 'absolute',
  },
  errImage: {
    height: 'auto !important',
    objectFit: 'none',
  },
  mobileEdgeDevice: {
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      marginBottom: 15,
    },
  },
}))
