import React, { Children } from 'react'
import PopUp from '../index'
import extensionIcon from './../../../assets/images/Chirpyest-extension-icon.png'
import crossIcon from './../../../assets/images/cross.png'
import { styles } from '../styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface PopupsProps {
  t: TFunction
  open: boolean
  handleClose: any
}

const BrowserExtensionPopup = ({ t, open, handleClose }: PopupsProps) => {
  const classes = styles()

  return (
    <PopUp
      open={open}
      title={
        <>
          <img
            src={extensionIcon}
            alt={t('popUps.imageAlts.extension')}
            className={classes.imageContainer}
          />
          <div className={classes.textContainer}>
            {t('popUps.text.thisIsWhat')}
          </div>
          <button className={classes.closeButton} onClick={handleClose}>
            <img
              src={crossIcon}
              alt={t('popUps.imageAlts.close')}
              className={classes.closeButton}
            />
          </button>
        </>
      }
      placement="top"
      customStyle={classes.browserExtensionPopUpPosition}
    >
      <div></div>
    </PopUp>
  )
}

export default withTranslation()(BrowserExtensionPopup)
