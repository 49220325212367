import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  round: {
    position: 'relative',
    ['& label']: {
      backgroundColor: '#fff',
      border: '1px solid #ABABAB',
      borderRadius: '50%',
      cursor: 'pointer',
      height: 20,
      left: 0,
      position: 'absolute',
      top: 0,
      width: 20,
    },
    ['& label:after']: {
      borderTop: 'none',
      borderRight: 'none',
      content: '""',
      height: '6px',
      left: '7px',
      opacity: 0,
      position: 'absolute',
      top: '8px',
      width: '12px',
    },
    ['& input[type="radio"]']: {
      visibility: 'hidden',
    },
    ['& input[type="radio"]:checked + label']: {
      backgroundColor: props => (props.disabled ? '#ABABAB' : '#000'),
    },
    ['& input[type="radio"]:checked + label:after']: {
      opacity: 1,
    },
  },
  label: {
    paddingLeft: 10,
    margin: 0,
  },
  disabled: {
    color: '#ABABAB',
  },
}))
